import React, { useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";
import ProjectAdd from "./ProjectAdd";
import { BsInfo, BsPersonPlusFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import ProjectDelete from "./ProjectDelete";
import timesheetsRepository from "../../repository/timesheetsRepository";
import WorkPackagesAll from "../WorkPackages/WorkPackagesAll";
import membersRepository from "../../repository/membersRepository";
import ProjectMembersAdd from "./ProjectMembersAdd";
import ProjectListMembersEdit from "./ProjectListMembersEdit";
import { HiFolder } from "react-icons/hi";
import ProjectListMembers from "./ProjectListMembers";
import { FaUsers } from "react-icons/all";
import { useHistory } from "react-router-dom";
import Login from "../Login/Login";
import TimesheetService from "../../repository/timesheetsRepository";
import ProjectService from "../../repository/projectRepository";

const ProjectsAll = (props) => {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
  const [memberName, setMemberName] = React.useState([]);
  const [projectIds, setProjectIds] = React.useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const [id, setId] = React.useState();
  const [unassignedUsers, setUnassignedUsers] = React.useState([]);

  const usersUn = (id) => {
    membersRepository.fetchUnAssignedUsers(id).then((data) => {
      setUnassignedUsers(data.data);
    });
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleOpenDelete = (e) => {
    const value = e.target.value;
    setId(value);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const bodyProjectAdd = (
    <ProjectAdd
      handleCloseAdd={handleCloseAdd}
      universities={props.universities}
    ></ProjectAdd>
  );

  const bodyDelete = (
    <ProjectDelete
      handleCloseDelete={handleCloseDelete}
      projectId={id}
    ></ProjectDelete>
  );

  const [workPackages, setWorkPackages] = React.useState([]);
  const handleClick = (e) => {
    const idT = e.target.id;
    timesheetsRepository
      .getAllWorkingPackagesByProject(idT)
      .then((snapshot) => {
        let projectsWPs = [];
        if (snapshot.data.length > 0) {
          projectsWPs = snapshot.data;
        }
        history.push({
          pathname: "/project/work_packages/" + idT,
          state: {
            workPackages: projectsWPs,
            projectId: idT,
          },
        });
      });
  };
  const [openInfo, setOpenInfo] = React.useState(false);
  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const bodyInfo = (
    <WorkPackagesAll
      workPackages={workPackages}
      projectId={id}
    ></WorkPackagesAll>
  );

  const [openAddMember, setOpenAddMember] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [memberTypes, setMemberTypes] = React.useState([]);
  const handleOpenAddMember = (e) => {
    const value = e.target.value;
    setId(value);
    membersRepository.fetchUnAssignedUsers(value).then((users) => {
      if (users.data.length > 0) {
        setUsers(users.data);
      } else {
        setUsers([]);
      }
    });
    membersRepository.fetchMemberType().then((types) => {
      if (types.data.result.length > 0) {
        setMemberTypes(types.data.result);
      } else {
        setMemberTypes([]);
      }
    });
    setOpenAddMember(true);
  };
  const handleCloseAddMember = () => {
    setOpenAddMember(false);
  };

  const bodyAddMember = (
    <ProjectMembersAdd
      handleCloseAddMember={handleCloseAddMember}
      projectId={id}
      memberType={memberTypes}
      users={users}
    ></ProjectMembersAdd>
  );
  const [filteredProjects, setFilteredProjects] = React.useState([]);

  const filterTable = (e) => {
    const value = e.target.value;

    if (value === null || value === "") {
      setFilteredProjects(props.projects);
    } else {
      let uniList = [];
      props.projects.map((uni) => {
        if (
          uni.name.toLowerCase().includes(value.toLowerCase()) ||
          uni.projectNumber.toLowerCase().includes(value.toLowerCase()) ||
          uni.university.name.toLowerCase().includes(value.toLowerCase()) ||
          uni.estimatedBudget
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          uni.startDate.toLowerCase().includes(value.toLowerCase()) ||
          uni.endDate.toLowerCase().includes(value.toLowerCase()) ||
          uni.approved.toString().toLowerCase().includes(value.toLowerCase())
        ) {
          uniList.push(uni);
        }
      });
      if (uniList.length > 0) {
        setFilteredProjects(uniList);
      } else {
        setFilteredProjects(props.projects);
      }
    }
   
  };
  const [projectName, setProjectName] = React.useState("");
  const [projectId, setProjectId] = React.useState("");
  const [membersList, setMembersList] = React.useState([]);
  const [openMemberList, setOpenMemberList] = React.useState(false);
  const handleOpenMemberList = (e) => {
    const id = e.target.id;
    // membersRepository.fetchUnAssignedUsers(id).then((data) => {
    //   setUnassignedUsers(data.data);
    // });

    setOpenMemberList(true);
  };
  const handleCloseMemberList = () => {
    setOpenMemberList(false);
  };
  const [memberTypeList, setMemberTypeList] = React.useState([]);
  const bodyMemberList = (
    <ProjectListMembers
      handleOpenMemberList={handleOpenMemberList}
      members2={unassignedUsers}
      members1={membersList}
      members={props.members}
      projectName={projectName}
      memberTypeList={memberTypeList}
      users={users}
      projectId={projectId}
      memberTypes={props.memberTypes}
    ></ProjectListMembers>
  );

  // const bodyEdit = (
  //     <ProjectListMembersEdit members={membersList} projectName={projectName} memberTypeList={memberTypeList} users={users}
  //                         projectId={projectId}></ProjectListMembersEdit>
  // )
  const generateTimesheets = () => {
    TimesheetService.autoGenerateTimeSheets().then((res) => {
      timeoutSuccessMessage();
    });
    setSuccess(false);
  };
  const timeoutSuccessMessage = () => {
    let timer;
    setSuccess(true);
    setMessage("You have successfully generated timesheets!");
    if (clearPreviousTimeout !== undefined) {
      clearTimeout(clearPreviousTimeout);
    }
    timer = setTimeout(() => {
      setSuccess(false);
    }, 3000);
    setClearPreviousTimeout(timer);
  };
  const handleShowMembers = (e) => {
    const name = e.target.name;
    setProjectName(name);
    const id = e.target.id;
    setProjectId(id);
    // membersRepository.getMembersByProject(id).then(async (members) => {
    //   let dataMembers = members.data;
    //   setMembersList(dataMembers);
    // });

    membersRepository.fetchMemberType().then((snapshot) => {
      setMemberTypeList(snapshot.data.result);
    });

    // membersRepository.fetchUnAssignedUsers(id).then((res) => {
    //   let unassignedUs = res.data;
    //   setUnassignedUsers(unassignedUs);
    // });
    handleOpenMemberList(e);
  };

  const getMemberRoleByProject = async (id) =>  {
    let memberNames = [];
    let projectUserHelper;
    let projectIdsH = [];
    for (var i = 0; i < props.projects.length; i++) {
      projectIdsH.push(props.projects[i].id);
    }
    for (var j = 0; j < projectIdsH.length; j++) {
      projectUserHelper = {
        memberId: localStorage.getItem("memberId"),
        projectId: projectIdsH[j],
      };
      let res = await ProjectService.getMemberByProject(projectUserHelper)
      setMemberName(prevState => [
        ...prevState, {
          pid: projectIdsH[j],
          pname: res.data,
        }
      ]);
    }
  }
  
  useEffect(() => {
    // getMemberByid();
    getMemberRoleByProject();
  }, [props.projects]);

  if (localStorage.getItem("email") !== null) {
    return (
      <div className="container">
        {/* <div style={{display:"flex", justifyContent:"space-between"}}> */}
        {/* {localStorage.getItem("role") === "ROLE_ADMIN" && (
          <button className="btn  mt-3 btn-info" onClick={generateTimesheets}>
            Generate Timesheets
          </button>
        )} */}
        {success === true ? (
          <div
            style={{
              width: "500px",
              position: "absolute",
              top: "40px",
              right: "2px",
            }}
            className="alert alert-success alert-dismissible fade show mt-3"
            role="alert"
          >
            {message}
          </div>
        ) : (
          <div></div>
        )}
        {/* </div> */}
        <Modal
          open={openAdd}
          onClose={handleCloseAdd}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyProjectAdd}
        </Modal>
        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyDelete}
        </Modal>

        <Modal
          open={openInfo}
          onClose={handleCloseInfo}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyInfo}
        </Modal>

        <Modal
          open={openAddMember}
          onClose={handleCloseAddMember}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyAddMember}
        </Modal>

        <Modal
          open={openMemberList}
          onClose={handleCloseMemberList}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyMemberList}
        </Modal>
        <div className="row mt-5">
          <div className="col mainCol">
            <Card className="customCard">
              <CardHeader title={"Projects"}></CardHeader>
              <CardBody>
                <input
                  className="filterField"
                  type="text"
                  onKeyUp={filterTable}
                  placeholder="Filter"
                />
                <div className="table-rep-plugin">
                  <div className="" data-pattern="priority-columns">
                    <TableContainer>
                      <Table id="universityTable" className="table tableCustom">
                        <TableHead>
                          <TableRow>
                            <TableCell data-priority="3">
                              Project Name
                            </TableCell>
                            <TableCell data-priority="3">
                              Project Number
                            </TableCell>
                            <TableCell data-priority="3">Institution</TableCell>
                            <TableCell data-priority="3">
                              Project Manager
                            </TableCell>
                            {/* <TableCell data-priority="3">
                             Project Role
                            </TableCell> */}
                            <TableCell data-priority="3">
                              Estimated Budget
                            </TableCell>
                            <TableCell data-priority="3">Start Date</TableCell>
                            <TableCell data-priority="3">End Date</TableCell>
                            <TableCell data-priority="3">Approved</TableCell>
                            <TableCell data-priority="3">
                              <a
                                className="btn btn-sm infoBtn"
                                onClick={handleOpenAdd}
                                style={
                                  localStorage.getItem("role") ===
                                    "ROLE_ADMIN" ||
                                    localStorage.getItem("role") ===
                                    "ROLE_FINANCE"
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <IoMdAdd className="buttonIcon" />
                              </a>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredProjects.length > 0
                            ? filteredProjects
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((term) => {
                                return (
                                  <TableRow className="innerRow">
                                    <TableCell className="innerCell">
                                      {term.name}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.projectNumber}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.university.name}
                                    </TableCell>
                                    <TableCell className="innerCell projectManagerCell">
                                      {term.projectManager}
                                    </TableCell>
                                    {/* <TableCell className="innerCell projectManagerCell">
                                       {()=>getMemberRoleByProject(term.id)}
                                      </TableCell> */}
                                    <TableCell className="innerCell">
                                      {term.estimatedBudget}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.startDate}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.endDate}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.approved ? "yes" : "no"}
                                    </TableCell>
                                    <TableCell
                                      style={
                                        localStorage.getItem("role") ===
                                          "ROLE_ADMIN" ||
                                          localStorage.getItem("role") ===
                                          "ROLE_FINANCE" ||
                                          localStorage.getItem("role") ===
                                          "ROLE_MANAGER" ||
                                          (memberName.find(el => el.pid == term.id) && memberName.find(el => el.pid == term.id).pname == "Manager")
                                          ? { visibility: "visible" }
                                          : term.roleOfLogged !== null
                                            ? term.roleOfLogged !==
                                              "Coordinator"
                                              ? { display: "none" }
                                              : { visibility: "visible" }
                                            : { visibility: "visible" }
                                      }
                                    >
                                      <button
                                        name={term.name}
                                        onClick={handleShowMembers}
                                        id={term.id}
                                        className="btn btn-sm infoBtn"
                                      >
                                        <FaUsers className="buttonIcon" />{" "}
                                        Members
                                      </button>
                                      <button
                                        name={term.name}
                                        onClick={handleClick}
                                        id={term.id}
                                        className="btn btn-sm infoBtn"
                                      >
                                        <HiFolder className="buttonIcon" />{" "}
                                        Packages
                                      </button>

                                      {/* <button value={term.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenAddMember}><BsPersonPlusFill
                                                                    className="buttonIcon"/> Add member
                                                                </button> */}

                                      <button
                                        value={term.id}
                                        className="btn btn-sm infoBtn"
                                        onClick={handleOpenDelete}
                                      >
                                        <AiFillDelete className="buttonIcon" />{" "}
                                        Delete
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : props.projects
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((term) => {
                                //  getMemberRoleByProject(term.id)
                                return (
                                  <TableRow className="innerRow">
                                    <TableCell className="innerCell">
                                      {term.name}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.projectNumber}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.university.name}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.projectManager}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.estimatedBudget}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.startDate}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.endDate}
                                    </TableCell>
                                    <TableCell className="innerCell">
                                      {term.approved ? "yes" : "no"}
                                    </TableCell>
                                    <TableCell
                                      style={
                                        localStorage.getItem("role") ===
                                          "ROLE_ADMIN" ||
                                          localStorage.getItem("role") ===
                                          "ROLE_FINANCE" ||
                                          localStorage.getItem("role") ===
                                          "ROLE_MANAGER" ||
                                          (memberName.find(el => el.pid == term.id) && memberName.find(el => el.pid == term.id).pname == "Manager")
                                          ? { visibility: "visible" }
                                          : term.roleOfLogged !== null
                                            ? term.roleOfLogged !==
                                              "Coordinator"
                                              ? { display: "none" }
                                              : { visibility: "visible" }
                                            : { visibility: "visible" }
                                      }
                                    >
                                      <button
                                        name={term.name}
                                        onClick={handleShowMembers}
                                        id={term.id}
                                        className="btn btn-sm infoBtn"
                                      >
                                        <FaUsers className="buttonIcon" />{" "}
                                        Members
                                      </button>
                                      <br />
                                      <button
                                        name={term.name}
                                        onClick={handleClick}
                                        id={term.id}
                                        className="btn btn-sm infoBtn"
                                      >
                                        <HiFolder className="buttonIcon" />{" "}
                                        Packages
                                      </button>
                                      <br />

                                      {/* <button value={term.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenAddMember}><BsPersonPlusFill
                                                                    className="buttonIcon"/> Add member
                                                                </button>  */}

                                      <button
                                        value={term.id}
                                        className="btn btn-sm infoBtn"
                                        onClick={handleOpenDelete}
                                      >
                                        <AiFillDelete className="buttonIcon" />{" "}
                                        Delete
                                      </button>
                                      <br />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={
                          filteredProjects.length > 0
                            ? filteredProjects.length
                            : props.projects.length
                        }
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  } else {
    return <Login />;
  }
};

export default ProjectsAll;
