import React, {useEffect, useState} from "react";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {IoMdAdd} from "react-icons/io";
import {BsInfo} from "react-icons/bs";
import {AiFillDelete} from "react-icons/ai";
import WorkPackageAdd from "./WorkPackageAdd";
import {useHistory} from "react-router-dom";
import WorkPackageDelete from "./WorkPackageDelete";
import projectRepository from "../../repository/projectRepository";
import {GiAlarmClock, GoTasklist} from "react-icons/all";
import Login from "../Login/Login";

const WorkPackagesAll = (props) => {
   
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [filteredPackages, setWorkingPackages] = useState([]);

    const handleClick = (e) => {
        const id = e.target.id;
        projectRepository.getTasksByWorkPackage(id).then((snapshot)=>{
            if (snapshot.data.length > 0){
                history.push({
                    pathname: '/work_packages/tasks/' + id,
                    state:{
                        tasks:snapshot.data,
                        workPackageId:id
                    }
                });}else{
                history.push({
                    pathname: '/work_packages/tasks/' + id,
                    state:{
                        tasks:[],
                        workPackageId:id
                    }
                })
            }
        });

    }
    const filterTable = (e) => {
        const value = e.target.value;
        if (value === null || value === "") {
            setWorkingPackages(props.location.state.workPackages);
        } else {
            let uniList = []
            props.location.state.workPackages.map(uni => {
                if (uni.name.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setWorkingPackages(uniList);
            } else {
                setWorkingPackages(props.location.state.workPackages);
            }
        }
    }
    const [id, setId] = React.useState();
    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpenAdd = () => {
        setOpenAdd(true);
    };


    const handleCloseAdd = () => {
        setOpenAdd(false);
    };
    const [openDelete, setOpenDelete] = React.useState(false);

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };

    useEffect(()=>{
        console.log(props)
    },[])
    const bodyAdd = (
        <WorkPackageAdd comesFrom={"parent"} projectId={props.location.state.projectId} handleCloseAdd={handleCloseAdd}></WorkPackageAdd>
    )
    const bodyDelete = (
        <WorkPackageDelete handleCloseDelete={handleCloseDelete} comesFrom={"parent"} id={id} projectId={props.location.state.projectId}></WorkPackageDelete>
    )
    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyAdd}
            </Modal>
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyDelete}
            </Modal>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard">
                        <CardHeader title={props.location.state.workPackages?props.location.state.workPackages[0]?"Work packages" + " " +"-" + " " + props.location.state.workPackages[0].project.name:"Work packages":"Work packages"}></CardHeader>

                        <CardBody>

                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            {/*<TextField id="standard-basic" label="Filter" onKeyPress={filterTable}/>*/}

                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns">
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Name</TableCell>

                                                    <TableCell data-priority="3">
                                                        <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                            <IoMdAdd
                                                                className="buttonIcon"/>
                                                        </a></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody id="universityTableBody">

                                                {filteredPackages.length > 0 ? filteredPackages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.name}</TableCell>
                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn">
                                                                    <GoTasklist
                                                                        className="buttonIcon"/> Tasks</button>
                                                                {/*<button id={uni.id} value={nameAndDean}*/}
                                                                {/*        className="btn btn-sm infoBtn"*/}
                                                                {/*        onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                                {/*    className="buttonIcon"/></button>*/}
                                                                <button value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }) : props.location.state.workPackages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.name}</TableCell>
                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn">
                                                                    <GoTasklist
                                                                        className="buttonIcon"/> Tasks</button>
                                                                {/*<button id={uni.id} value={nameAndDean}*/}
                                                                {/*        className="btn btn-sm infoBtn"*/}
                                                                {/*        onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                                {/*    className="buttonIcon"/></button>*/}
                                                                {/*<button id={timesheet.id}*/}
                                                                {/*        value={timesheet.maxHours}*/}
                                                                {/*        className="btn btn-sm infoBtn"*/}
                                                                {/*        disabled={timesheet.locked}*/}
                                                                {/*        onClick={handleOpenUpdateMaxHours}*/}
                                                                {/*><GiAlarmClock*/}
                                                                {/*    className="buttonIcon"/> Max hours</button>*/}
                                                                <button value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={props.location.state.workPackages.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />

                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>
        </div>
    )}else{
        return (
            <Login/>
        )
    }


}
export default WorkPackagesAll;