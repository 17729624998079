import axios from 'axios';
import instanceA from './instanceA';


const instance = axios.create({
    baseURL: process.env.REACT_APP_HOST_ENV,
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

const AuthenticationService ={
    loginUser: (request) => {
        return instance.post('/login', request, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + request
            }
        })
    },
     userRole: () => {
         return instanceA.get('/users/getLoggedDetails', null, {
             headers: {
                 'Content-Type': 'application/json',
             }
         })
     }
}
export default AuthenticationService;
