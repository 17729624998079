import axios from "../custom-axios/axios";
import instance from "../custom-axios/axios";
import instanceA from "../custom-axios/instanceA";


const MemberRepository = {
    fetchMembers: () => {
        return instanceA.get("/members");
    },

    fetchMemberType: () =>{
        return instanceA.get("/memberTypes")
    },

    fetchUsers:()=>{
        return instanceA.get("/users")
    },

    getLoggedInfo:(id)=>{
        return instanceA.get(`/users/getLoggedDetails/${id}`);
    },
    getLoggedUserInfo:(id)=>{
        return instanceA.get(`/users/getLoggedDetails`);
    },
    fetchUnAssignedUsers:(projectId)=>{
        return instanceA.get(`/users/unAssignedToProject/${projectId}`);
    },

    getOneRole:(id)=>{
        return instanceA.get("/roles/"+id);
    },

    fetchRole:()=>{
        return instanceA.get("/roles")
    },

    addUser: (email, firstName, lastName, username, role)=>{
      return instanceA.post("users/add",{
          "email": email,
          "firstName" : firstName,
          "lastName" : lastName,
          "username" : username,
          "role" : role
      });
    },

    editUser:(id, email, firstName, lastName,username, role) => {
        instanceA.post(`/users/edit/${id}`, {
            "id":id,
            "email":email,
            "firstName":firstName,
            "lastName":lastName,
            "username":username,
            "role": role
         });
    },

    addMember: (user, memberType, hourlyRate, project)=>{
        return instanceA.post("/members/add",{
            "user" : user,
            "memberType" : memberType,
            "hourlyRate" : hourlyRate,
            "projects": [project]
        });
    },

    getMemberType:(id)=>{
        return instanceA.get("/memberTypes/"+id);
    },
    getUser:(id)=>{
        return instanceA.get("/users/"+id);
    },
    
    deleteUser:(id)=>{
        return instanceA.delete(`/users/delete/${id}`)
    },

    getUsersNotContainingUsername:(username)=>{
        return instanceA.get("/users/notUsername/"+username);
    },

    deleteMember:(id)=>{
        return instanceA.delete(`/members/delete/${id}`);
    },
    getMembersByProject:(id)=>{
        return instanceA.get(`/members/project/${id}`);
    },
    getMembersByUserNot:(username)=>{
        return instanceA.get(`/members/userNot/${username}`);
    },
    assignUsersToTask:(memberIds,taskId)=>{
        return instanceA.post(`/members/assignToTask/${memberIds}/${taskId}`);
    },
    getMembersByAssignedProjectAndNotTask:(projectId,taskId)=>{
        return instanceA.get(`/members/findAllByAssignedProjectAndNotTask/${projectId}/${taskId}`);
    },
    getOneMember:(id)=>{
        return instanceA.get(`/members/${id}`);
    },

    getAllProjectsForMember:(id)=>{
        return instanceA.get(`/members/findAllProjectsForMember/${id}`);
    },

    getAllMemberMemberTypeProjectsByProject:(id)=>{
        return instanceA.get(`/memberMemberTypeProjects/findAllByProject/${id}`);
    },
    changeRoleInProject(memberId, memberTypeId, projectId){
        return instanceA.post(`/members/changeRoleInProject/${memberId}/${memberTypeId}/${projectId}`);
    },

    deleteMemberFromProject:(id)=>{
        return instanceA.post(`/memberMemberTypeProjects/deleteItFromProject/${id}`);
    },

    editMember:(memberId, memberTypeId, projectId, hourlyRate) => {

        instanceA.post(`/members/changeRoleInProject/${memberId}/${memberTypeId}/${projectId}/${hourlyRate}`, {
            "memberId":memberId,
            "memberTypeId":memberTypeId,
            "projectId":projectId,
            "hourlyRate":hourlyRate,
         });

    },
    findAllMembersByProject:(projectId)=>{
        return instanceA.get(`members/findAllByProject/${projectId}`);
    },
    getAllForTimesheets:()=>{
        return instanceA.get(`users/getAllForTimesheets`);
    }

}



export default MemberRepository;
