import React from "react";
import memberTypeRepository from "../repository/memberTypeRepository";
import {Card, CardBody} from "reactstrap";
import Login from "../components/Login/Login";

const MemberTypeEdit = (props) => {
    const [formData, updateFormData] = React.useState({
        id:props.id,
        name: props.name
    });

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }


    const submitForm = (e) => {
        e.preventDefault();
        const id = formData.id;
        console.log(id)
        const memberTypeHelper={
            id:formData.id,
            name:formData.name
        }
        console.log(memberTypeHelper)
        memberTypeRepository.editMemberType(memberTypeHelper).then(res=>{
             window.location="/memberTypes";
        })
        
    }

    const handleCancel = () => {
        props.handleCloseEdit();
    }

    if(localStorage.getItem("email")!==null){
        return (
            <div className="container">

                <div className="row mt-5" >
                    <div className="col mainCol">
                        <Card style={{marginTop:"10%"}}>
                            <CardBody>
                                <h3 className="formHeader">Edit Member Type</h3>
                                <form onSubmit={submitForm}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text"
                                               className="form-control inputForm"
                                               name="name" required id="name"
                                               onChange={handleChange}
                                               defaultValue={props.name}
                                        />
                                    </div>
                                    <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>
                                </form>
                                <button style={{marginTop:10}} className="btn saveBtn" onClick={handleCancel} type="button">Cancel</button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

        )}else{
        return (
            <Login/>
        )
    }

}
export default MemberTypeEdit;