import axios from "../custom-axios/axios";
import instance from "../custom-axios/axios";
import instanceA from "../custom-axios/instanceA";


const HolidayService = {
    fetchHolidays:()=>{
        return instanceA.get("/holidays");
    },
    addHoliday: (name, date) =>{
        return instanceA.post("/holidays/add",{
            "name": name,
            "date": date
        });
    },
    deleteHolidays:(id)=>{
        return instanceA.delete(`/holidays/delete/${id}`)
    },
    
    editHoliday: (id, name, date) =>{
        return instanceA.put("/holidays/edit/"+id,{
            "id": id,
            "name": name,
            "date": date
        });
    },


}

export default HolidayService;