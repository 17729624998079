import {Card, CardBody} from "reactstrap";
import Select from "react-select";
import React from "react";
import projectRepository from "../../repository/projectRepository";
import timesheetsRepository from "../../repository/timesheetsRepository";
import Login from "../Login/Login";

const RoleAdd = (props) => {
    const [formData, updateForData] = React.useState({
        name: "",


    })
    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const name = formData.name;
        timesheetsRepository.createRole(name).then(()=>{
            window.location.href="/users"
        })


    }

    const handleCancel = () => {
        props.handleCloseAddRole();
    }
    if (localStorage.getItem("email")!==null){
    return(
        <div className="container">

            <div className="row mt-5">
                <Card style={{marginLeft: "20%", marginTop: "10%"}}>
                    <CardBody>
                        <h3 className="formHeader">Create Role</h3>
                        <div className="col">

                            <form onSubmit={onFormSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input type="text"
                                           className="form-control inputForm"
                                           name="name" required id="name"
                                           onChange={handleChange}


                                    />
                                </div>


                                <button type="button" id="submit" className="btn saveBtn" onClick={onFormSubmit}>Save</button>
                                <br/>
                                <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                            </form>
                        </div>
                    </CardBody></Card>
            </div>
        </div>
    )}else{
        return (
            <Login/>
        )
    }
}
export default RoleAdd;