import React from "react";
import {Card, CardBody} from "reactstrap"
import '../../assets/general.css';
import {RiEdit2Fill} from "react-icons/ri"
import {AiFillDelete} from "react-icons/ai"
import {BsInfo} from "react-icons/bs"
import {IoMdAdd} from "react-icons/io"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Modal,
    TableContainer
} from '@material-ui/core'
import CardHeader from "@material-ui/core/CardHeader";
import UniversityAdd from "../Universities/universityAdd";
import UniversityEdit from "../Universities/UniversityEdit";
import UniversityDelete from "../Universities/UniversityDelete";
import timesheetsRepository from "../../repository/timesheetsRepository";
import projectRepository from "../../repository/projectRepository";
import UniversityDetails from "./universityDetails";
import {HiFolderAdd} from "react-icons/hi";
import Login from "../Login/Login";


const UniversitiesAll = (props) => {


    const [filteredUniversities, setFilterUniversities] = React.useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [universitiesFiltered,setUniversities]=React.useState([]);
    const [projects, setProjects] = React.useState([]);


    const handleClick = (e) => {
        const id = e.target.id;
        timesheetsRepository.getOneUniversity(id).then((snapshot)=>{
           setProjects(snapshot.data.result);
        });

        projectRepository.getUniversity(id).then((snapshot)=>{
           
            let array = [...universitiesFiltered];
            array.push(snapshot.data);
            setUniversities(array);
        });

        handleOpenInfo(e);


    }


    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };


    const [openEdit, setOpenEdit] = React.useState(false);
    const [name, setName] = React.useState();
    const [dean, setDean] = React.useState();
    const [id, setId] = React.useState();
    const handleOpenEdit = (e) => {
        const value = e.target.value;
        const json = JSON.parse(value);
        const id = e.target.id;
        setDean(json.dean);
        setName(json.name);
        setId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {

        setOpenEdit(false);
    };

    const [openDelete, setOpenDelete] = React.useState(false);

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };

    const [openInfo, setOpenInfo] = React.useState(false);

    const handleOpenInfo = () => {
        setOpenInfo(true);
    };

    const handleCloseInfo = () => {
        setOpenInfo(false);
    };


    const bodyAdd = (
        <UniversityAdd handleCloseAdd={handleCloseAdd}></UniversityAdd>
    );

    const bodyEdit = (

        <UniversityEdit handleCloseEdit={handleCloseEdit} uniName={name} uniDean={dean} uniId={id}></UniversityEdit>
    )

    const bodyDelete = (
        <UniversityDelete handleCloseDelete={handleCloseDelete} uniId={id}></UniversityDelete>
    )

    const bodyInfo = (
        <UniversityDetails universities={universitiesFiltered} projects={projects}></UniversityDetails>
    )

    const filterTable = (e) => {
        const value = e.target.value;
      

        if (value === null || value === "") {
            setFilterUniversities(props.universities);
        } else {
            let uniList = []
            props.universities.map(uni => {
                if (uni.name.toLowerCase().includes(value.toLowerCase()) || uni.dean.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setFilterUniversities(uniList);
            } else {
                setFilterUniversities(props.universities);
            }
        }
    }
    if (localStorage.getItem("email")!==null){
    return (
        <div className="container">
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyAdd}

            </Modal>

            <Modal open={openEdit}
                   onClose={handleCloseEdit}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                {bodyEdit}
            </Modal>

            <Modal open={openDelete}
                   onClose={handleCloseDelete}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                {bodyDelete}
            </Modal>

            <Modal open={openInfo}
                   onClose={handleCloseInfo}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                {bodyInfo}
            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard">
                        <CardHeader title={"Institutions"}></CardHeader>
                        <CardBody>

                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            {/*<TextField id="standard-basic" label="Filter" onKeyPress={filterTable}/>*/}

                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Name</TableCell>
                                                    <TableCell data-priority="3">Dean</TableCell>
                                                    <TableCell data-priority="3">
                                                        <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                            <IoMdAdd
                                                                className="buttonIcon"/>
                                                        </a></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody id="universityTableBody">

                                                {filteredUniversities.length > 0 ? filteredUniversities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    const nameAndDean = JSON.stringify({
                                                        "name": uni.name,
                                                        "dean": uni.dean
                                                    });

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.name}</TableCell>
                                                            <TableCell className="innerCell">{uni.dean}</TableCell>
                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn">
                                                                    <HiFolderAdd
                                                                        className="buttonIcon"/> Projects</button>
                                                                <button id={uni.id} value={nameAndDean}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenEdit}><RiEdit2Fill
                                                                    className="buttonIcon"/> Edit</button>
                                                                <button value={uni.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }) : props.universities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {
                                                    const val = JSON.stringify(uni.projects);
                                                    const nameAndDean = JSON.stringify({
                                                        "name": uni.name,
                                                        "dean": uni.dean
                                                    });

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.name}</TableCell>
                                                            <TableCell className="innerCell">{uni.dean}</TableCell>
                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn">
                                                                    <HiFolderAdd
                                                                        className="buttonIcon"/> Projects</button>
                                                                <button id={uni.id} value={nameAndDean}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenEdit}><RiEdit2Fill
                                                                    className="buttonIcon"/> Edit</button>
                                                                <button value={uni.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={filteredUniversities.length>0?filteredUniversities.length:props.universities.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />

                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>
        </div>
    )}else {
        return (
            <Login/>
        )
    }
}

export default UniversitiesAll;