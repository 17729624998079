import axios from "../custom-axios/axios";
import instanceA from "../custom-axios/instanceA";


const ItemHourRepository = {
    getItemHourByMemberTaskTimeSheetDay:(memberId,taskId,timeSheetId,day)=>{
        return instanceA.get(`/itemHours/findByMemberTaskTimeSheetDay/${memberId}/${taskId}/${timeSheetId}/${day}`)
    },

    updateHours:(id, hours)=>{
        return instanceA.post(`/itemHours/updateHours/${id}`,{hours:hours})
    },
    getItemHoursByMemberTaskTimeSheet:(memberId, taskId, timesheetId)=>{
        return instanceA.get(`/itemHours/findByMemberTaskTimeSheet/${memberId}/${taskId}/${timesheetId}`);
    },
    getItemsByTask:(id)=>{
        return instanceA.get(`/itemHours/task/${id}`)
    },
    getAllTasksMembers:(memberId,taskId)=>{
        return instanceA.get(`/tasksMembers/findAllByMemberAndTask/${memberId}/${taskId}`);
    },
    getTasksMembersHours:(memberId,taskId)=>{
        return instanceA.get(`/tasksMembers/tasksMembersTotalHours/${memberId}/${taskId}`);
    },
    getFindByTask:(taskId)=>{
        return instanceA.get(`/tasksMembers/findByTask/${taskId}`)
    },
    getTimeSheetTaskSum:(timeSheetId,taskId)=>{
        return instanceA.get(`/timesheetTasksSum/getSum/${timeSheetId}/${taskId}`)
    },

}
export default ItemHourRepository;