import React from "react";
import {Card, CardBody} from "reactstrap";
import projectRepository from "../../repository/projectRepository";
import {useHistory} from "react-router-dom";

const ItemAdd = (props) => {
    const history = useHistory();
    const [formData, updateForData] = React.useState({
        maxHours: "",
        dateStart: "",
        dateEnd: "",


    })

    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }


    const onFormSubmit = (e) => {
        e.preventDefault();
        const maxHours = formData.maxHours;
        const dateEnd = formData.dateEnd;
        const dateStart = formData.dateStart;
        const task = props.taskId;
        projectRepository.getOneTask(task).then((snapshot) => {
            projectRepository.addItem(maxHours, dateStart, dateEnd, snapshot.data).then(() => {
                projectRepository.getItemsByTask(task).then((resolve) => {
                    history.push({
                        pathname: "/items/task/" + task,
                        state: {
                            items: resolve.data,
                            taskId: task
                        }
                    });
                    history.go(0);
                })
            })
        })

    }

    return (
        <div className="container">

            <div className="row mt-5">
                <Card style={{marginLeft: "20%", marginTop: "10%"}}><CardBody>
                    <h3 className="formHeader">Add Item</h3>
                    <div className="col">

                        <form onSubmit={onFormSubmit}>


                            <div className="form-group">
                                <input type="text" className="form-control" id="maxHours"
                                       name="maxHours" placeholder="Maximum Hours"
                                       onChange={handleChange}/>


                            </div>

                            <div className="form-group">
                                <input type="date" className="form-control inputForm" id="dateStart"
                                       name="dateStart" placeholder="Start Date"
                                       onChange={handleChange}/>


                            </div>
                            <div className="form-group">
                                <input type="date" className="form-control inputForm" id="dateEnd"
                                       name="dateEnd" placeholder="End Date"
                                       onChange={handleChange}/>


                            </div>


                            <button type="submit" id="submit" className="btn saveBtn">Save</button>
                            <br/>

                        </form>
                    </div>
                </CardBody></Card>
            </div>
        </div>

    )
}

export default ItemAdd;