import React, { useEffect, useRef } from "react";
import { Card, CardBody } from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import membersRepository from "../../repository/membersRepository";
import { AiFillDelete } from "react-icons/ai";
import { Modal } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";
import ProjectMembersAdd from "./ProjectMembersAdd";
import ProjectListMembersEdit from "./ProjectListMembersEdit";
import ProjectListMembersDelete from "./ProjectListMembersDelete";
import Login from "../Login/Login";
import { RiEdit2Fill } from "react-icons/ri";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import MemberRepository from "../../repository/membersRepository";
import ProjectService from "../../repository/projectRepository";
import { useCallback } from "react";
const ProjectListMembers = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [hourlyRate, setRate] = React.useState();
  const [memberType, setMemberType] = React.useState();
  const [memberTypeId, setMemberTypeId] = React.useState();
  const [idMember, setIdMember] = React.useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [projectMembers, setProjectMembers] = React.useState([]);
  const [id, setId] = React.useState();
  const [memberTypeName, setMemberTypeName] = React.useState([]);

  const handleOpenAddMember = () => {
    setOpenAdd(true);
  };

  const handleCloseAddMember = () => {
    setOpenAdd(false);
  };
  const getAllMembersByProject = () => {
    MemberRepository.findAllMembersByProject(props.projectId).then((res) => {
      setProjectMembers(res.data);
    });
  };

  const getMemberRoleByProject = async () => {
    let projectMembersList = [];
    let projectUserHelper;
    for (var i = 0; i < projectMembers.length; i++) {
      projectMembersList.push(projectMembers[i].id);
    }
    for (var j = 0; j < projectMembersList.length; j++) {
      projectUserHelper = {
        memberId: projectMembersList[j],
        projectId: props.projectId,
      };
      let res = await ProjectService.getMemberByProject(projectUserHelper);
      setMemberTypeName((prevState) => [
        ...prevState,
        {
          memberId: projectMembersList[j],
          memberName: res.data,
        },
      ]);
    }
  };

  useEffect(() => {
    getAllMembersByProject();
    setMemberTypeName([]);
    getMemberRoleByProject();
  }, [projectMembers.length]);
  const bodyProjectAdd = (
    <ProjectMembersAdd
      members={props.members}
      handleCloseAddMember={handleCloseAddMember}
      projectId={props.projectId}
      users={props.users}
      memberType={props.memberTypeList}
    ></ProjectMembersAdd>
  );

  const handleOpenEdit = (e) => {
    const value = e.target.value;
    const json = JSON.parse(value);
    const id = e.target.id;
    setFirstName(json.firstName);
    setLastName(json.lastName);
    setEmail(json.email);
    setRate(json.hourlyRate);
    setMemberType(json.memberType);
    setMemberTypeId({
      value: json.memberType.id,
      name: json.memberType.name,
    });
    setId(id);

    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const bodyUserEdit = (
    <ProjectListMembersEdit
      members={props.members}
      firstName={firstName}
      lastName={lastName}
      email={email}
      hourlyRate={hourlyRate}
      memberType={memberType}
      memberTypeList={props.memberTypeList}
      memberTypeId={memberTypeId}
      handleCloseEdit={handleCloseEdit}
      projectId={props.projectId}
      id={id}
      memberTypes={props.memberTypes}
    ></ProjectListMembersEdit>
  );

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleOpenDelete = (e) => {
    const value = e.target.value;
    setId(value);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const bodyDelete = (
    <ProjectListMembersDelete
      handleCloseDelete={handleCloseDelete}
      projectId={props.projectId}
      membersIds={props.members}
      id={id}
    ></ProjectListMembersDelete>
  );

  if (localStorage.getItem("email") !== null) {
    return (
      <div className="container">
        <Modal
          open={openAdd}
          onClose={handleCloseAddMember}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyProjectAdd}
        </Modal>
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyUserEdit}
        </Modal>
        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyDelete}
        </Modal>
        <div className="row mt-5">
          <div className="col">
            <Card className="customCard">
              <CardHeader title={props.projectName + " - Members"}></CardHeader>
              <CardBody>
                <div className="table-rep-plugin">
                  <div className="" data-pattern="priority-columns">
                    <TableContainer>
                      <Table id="universityTable" className="table tableCustom">
                        <TableHead>
                          <TableRow>
                            <TableCell data-priority="3">First Name</TableCell>
                            <TableCell data-priority="3">Last Name</TableCell>
                            <TableCell data-priority="3">Email</TableCell>
                            <TableCell data-priority="3">
                              Project Role
                            </TableCell>
                            <TableCell data-priority="3">Hourly Rate</TableCell>
                            <TableCell data-priority="3">
                              <a
                                className="btn btn-sm infoBtn"
                                onClick={handleOpenAddMember}
                              >
                                <IoMdAdd className="buttonIcon" />
                              </a>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            projectMembers.map((term) => {
                              const userEdit = JSON.stringify({
                                firstName: term.user.firstName,
                                lastName: term.user.lastName,
                                email: term.user.email,
                                memberType: term.memberType,
                                hourlyRate: term.hourlyRate,
                              });

                              return (
                                <TableRow className="innerRow">
                                  <TableCell className="innerCell">
                                    {term.user.firstName}
                                  </TableCell>
                                  <TableCell className="innerCell">
                                    {term.user.lastName}
                                  </TableCell>
                                  <TableCell className="innerCell">
                                    {term.user.email}
                                  </TableCell>
                                  <TableCell className="innerCell">
                                    {memberTypeName.find(
                                      (el) => el.memberId === term.id
                                    ) && memberTypeName.find(el => el.memberId == term.id).memberName}
                                  </TableCell>
                                  <TableCell className="innerCell">
                                    {term.hourlyRate}
                                  </TableCell>
                                  <TableCell>
                                    {/* <button id={term.id}  value={userEdit}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenEdit} ><RiEdit2Fill
                                                                    className="buttonIcon"/></button>    */}
                                    <button
                                      value={term.id}
                                      className="btn btn-sm infoBtn"
                                      onClick={handleOpenDelete}
                                    >
                                      <AiFillDelete className="buttonIcon" />{" "}
                                      Delete
                                    </button>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                            // : props.members1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                            //     const userEdit = JSON.stringify({
                            //         "firstName": term.user.firstName,
                            //         "lastName": term.user.lastName,
                            //         "email": term.user.email,
                            //         "memberType": term.memberType,
                            //         "hourlyRate": term.hourlyRate,
                            //     });
                            //     return (
                            //         <TableRow className="innerRow">
                            //             <TableCell className="innerCell">{term.user.firstName}</TableCell>
                            //             <TableCell
                            //                 className="innerCell">{term.user.lastName}</TableCell>
                            //             <TableCell
                            //                 className="innerCell">{term.user.email}</TableCell>
                            //             <TableCell
                            //                 className="innerCell">{term.memberType.name}</TableCell>
                            //             <TableCell
                            //                 className="innerCell">{term.hourlyRate}</TableCell>
                            //             <TableCell>
                            //             <button id={term.id}  value={userEdit}
                            //                     className="btn btn-sm infoBtn"
                            //                     onClick={handleOpenEdit}><RiEdit2Fill
                            //                 className="buttonIcon"/></button>
                            //                 <button value={term.id} className="btn btn-sm infoBtn"
                            //                        onClick={handleOpenDelete} ><AiFillDelete
                            //                     className="buttonIcon"/> Delete</button>
                            //             </TableCell>
                            //         </TableRow>
                            //     );
                            // })
                          }
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={
                          props.members1.length > 0
                            ? props.members1.length
                            : props.members1.length
                        }
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableContainer>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  } else {
    return <Login />;
  }
};
export default ProjectListMembers;
