import React from "react";
import MemberTypeAdd from "./MemberTypeAdd";
import MemberTypeDelete from "./MemberTypeDelete";
import MemberTypeEdit from "./MemberTypeEdit";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {IoMdAdd} from "react-icons/io";
import {RiEdit2Fill} from "react-icons/ri";
import {AiFillDelete} from "react-icons/ai";
import Login from "../components/Login/Login";

const MemberTypeAll = props => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [openAdd, setOpenAdd] = React.useState(false);

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };


    const [openDelete, setOpenDelete] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };


    const [openEdit, setOpenEdit] = React.useState(false);
    const [name, setName] = React.useState();

    const handleOpenEdit = (e) => {

        const value = e.target.value;
        const json = JSON.parse(value);
        const id = e.target.id;
        setName(json.name);
        setId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {

        setOpenEdit(false);
    };

    const bodyMemberTypeAdd =(
        <MemberTypeAdd handleCloseAdd={handleCloseAdd} memberTypes={props.memberTypes}></MemberTypeAdd>
    )

    const bodyMemberTypeDelete =(
        <MemberTypeDelete handleCloseDelete={handleCloseDelete} comesFrom={"parent"} id={id}></MemberTypeDelete>

    )
    const bodyMemberTypeEdit =(
        <MemberTypeEdit handleCloseEdit={handleCloseEdit} id={id} name={name}></MemberTypeEdit>

    )

    const [filteredMemberTypes, setMemberTypes] = React.useState([])
    const filterTable = (e) => {
        const value = e.target.value;
      
        if (value === null || value === "") {
            setMemberTypes(props.memberTypes);
        } else {
            let uniList = []
            props.memberTypes.map(uni => {
                if (uni.name.toLowerCase().includes(value.toLowerCase()) ) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setMemberTypes(uniList);
            } else {
                setMemberTypes(props.memberTypes);
            }
        }

    }
    if(localStorage.getItem("email")!==null){
        return (

            <div className="container">
                <Modal
                    open={openAdd}
                    onClose={handleCloseAdd}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"

                >
                    {bodyMemberTypeAdd}

                </Modal>
                <Modal open={openEdit}
                       onClose={handleCloseEdit}
                       aria-labelledby="simple-modal-title"
                       aria-describedby="simple-modal-description"
                >
                    {bodyMemberTypeEdit}
                </Modal>
                <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyMemberTypeDelete}
                </Modal>

                <div className="row mt-5">
                    <div className="col mainCol">
                        <Card className="customCard">
                            <CardHeader title={"Member Types"}></CardHeader>
                            <CardBody>

                                <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>


                                <div className="table-rep-plugin">
                                    <div
                                        className=""
                                        data-pattern="priority-columns"
                                    >
                                        <TableContainer>
                                            <Table
                                                id="memberTypesTable"
                                                className="table tableCustom"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell data-priority="3">Name</TableCell>

                                                        <TableCell data-priority="3">
                                                            <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                                <IoMdAdd
                                                                    className="buttonIcon"/>
                                                            </a>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody id="userTableBody">
                                                    {filteredMemberTypes.length>0?filteredMemberTypes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term)=>{

                                                        const name = JSON.stringify({
                                                            "name": term.name

                                                        });

                                                        return(
                                                            <TableRow className="innerRow">
                                                                <TableCell className="innerCell">{term.name}</TableCell>
                                                                <TableCell>
                                                                    <button id={term.id} value={name}
                                                                            className="btn btn-sm infoBtn"
                                                                            onClick={handleOpenEdit}><RiEdit2Fill
                                                                        className="buttonIcon"/></button>
                                                                    <button value={term.id} className="btn btn-sm infoBtn"
                                                                            onClick={handleOpenDelete}><AiFillDelete
                                                                        className="buttonIcon"/></button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}):props.memberTypes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {

                                                            const nameAndDate = JSON.stringify({
                                                                "name": term.name
                                                            });

                                                            return (
                                                                <TableRow className="innerRow">
                                                                    <TableCell
                                                                        className="innerCell">{term.name}</TableCell>
                                                                    <TableCell>
                                                                        <button id={term.id} value={nameAndDate}
                                                                                className="btn btn-sm infoBtn"
                                                                                onClick={handleOpenEdit}><RiEdit2Fill
                                                                            className="buttonIcon"/></button>
                                                                        <button value={term.id} className="btn btn-sm infoBtn"
                                                                                onClick={handleOpenDelete}><AiFillDelete
                                                                            className="buttonIcon"/></button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}

                                                </TableBody>
                                            </Table>
                                            <TablePagination
                                                component="div"
                                                rowsPerPageOptions={[5, 10, 15]}
                                                count={filteredMemberTypes.length>0?filteredMemberTypes.length:props.memberTypes.length}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </TableContainer>

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                </div>

            </div>
        );}else{
        return (
            <Login/>
        )
    }
};


export default MemberTypeAll;