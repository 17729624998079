import instance from "../custom-axios/axios";
import axios from "../custom-axios/axios";
import instanceA from "../custom-axios/instanceA";


const TimesheetService = {
    fetchUniversities: () => {
        return instanceA.get("/universities");
    },
    addUniversity: (name, dean) => {
       
        return instanceA.post("/universities/",{
            name:name,
            dean:dean
        });
    },
    getOneUniversity:(id) => {
        return instanceA.get("/universities/projects/"+id);
    },


    editUniversity:(id,name,dean) => {
        return instanceA.post("/universities/edit/"+id, {
            id:id,name:name,dean:dean
        });
    },

    deleteUniversity:(id)=>{
        return instance.delete("/universities/delete/"+id);
    },

    getAllWorkPackages:()=>{
        return instanceA.get('/work_packages');
    },

    getAllWorkingPackagesByProject:(projectId)=>{
        return instanceA.get("/projects/project/"+projectId);
    },
    addWorkPackage:(name,project)=>{
        return instanceA.post("/work_packages",{
            name:name,project:project
        })
    },
    getProject:(id)=>{
        return instanceA.get('/projects/'+id)
    },

    deleteWorkPackage:(id)=>{
        return instanceA.post("/work_packages/delete/"+id);
    },
    getWorkPackage:(id)=>{
        return instanceA.get("/work_packages/"+id);
    },
    addTask:(name, description,workPackage,dateStart,dateEnd,maxHours)=>{
        return instanceA.post("/tasks", {
            name: name,
            description:description,
            workPackage:workPackage,
            dateEnd:dateEnd,
            dateStart:dateStart,
            maxHours:maxHours
        })
    },
    deleteTask:(id)=>{
        return instanceA.post("/tasks/delete/"+id);
    },
    getTimesheetsForMember:(id)=>{
        return instanceA.get(`/timesheets/member/${id}`);

    },
    timeSheetManageLocking:(id)=>{
        return instanceA.post(`/timesheets/manageLocking/${id}`);
    },
    timeSheetUpdateMaxHours:(id,hours)=>{
        return instanceA.post(`/timesheets/updateMaxHours/${id}`,{hours:hours});
    },
    getAllByMemberAndProjectsContains:(memberId,projectId)=>{
        return instanceA.get(`/timesheets/findAllByMemberAndProjectsContaining/${memberId}/${projectId}`);
    },
    getAllTaskMemberTimeSheets:(taskId,memberId,timeSheetId) => {
        return instanceA.get(`/taskMemberTimeSheet/findAllByTaskMemberTimeSheet/${taskId}/${memberId}/${timeSheetId}`);
        },
    taskMemberTimeSheetUpdateHours:(id, hours,task,member,timeSheet) => {
        return instanceA.post(`/taskMemberTimeSheet/updateMaxHours/${id}`,{task:task,member:member,timeSheet:timeSheet,hours:hours});
    },

    getOneTimeSheet:(id) => {
        return instanceA.get(`/timesheets/findById/${id}`)
    },

    getTaskMembersByMemberLength:(memberId)=>{
        return instanceA.get(`/tasksMembers/findByMemberLength/${memberId}`);
    },
    getTimeSheetMaxHours:(id)=>{
        return instanceA.get(`/timesheets/getTimeSheetMaxHours/${id}`);
    },

    createRole:(name)=>{
        return instanceA.post(`/roles/${name}`,);
    },
    getTimeSheetsByMonthAndYear:(page, size, month, year, userId)=>{
        return instanceA.get(`/timesheets/getAllByMonthAndYear/${page}/${size}`, {headers:{
            month:month,
            year:year,
            userId:userId
        }})
    },
    setHoursOfTimeSheetTask:(timesheetHelper)=>{
        return instanceA.post(`timesheets/setHoursOfTimesheetTask`, timesheetHelper,{headers:{
            'Content-Type':'application/json'
        }})
    },
    autoGenerateTimeSheets:()=>{
        return instanceA.get(`timesheets/generateTimesheets`);
    },
    lockTimeSheet:(timesheetLockHelper)=>{
        return instanceA.post(`timesheets/lock`, timesheetLockHelper);
    },
    unLockTimeSheet:(timesheetLockHelper)=>{
        return instanceA.post(`timesheets/unlock`, timesheetLockHelper)
    },
    calculateSumOfHours:(year, month, userId)=>{
        return instanceA.get(`timesheets/calculateSumOfHours`, {headers:{
                year:year,
                month:month,
                userId:userId
        }});
    },
    exportToExcelTimesheets:(reportHelper)=>{
        return instanceA.post(`timesheets/exportToExcel`, reportHelper, {headers:{
             "Content-disposition": "attachement; filename=timesheets.xlsx",
             "Access-Control-Expose-Headers": "Content-Disposition",
            //  "Content-Type":"application/octet-stream",
        

        }, responseType:"blob"})
    },
    generateTimeSheetsPersonal:(reportHelper)=>{
        return instanceA.post(`timesheets/generateTimesheetsPersonal`, reportHelper);
    }

}

export default TimesheetService;