import React from "react";
//import holidaysRepository from "../repository/holidaysRepository";
import {Card, CardBody} from "reactstrap";
import Login from "../components/Login/Login";
import memberTypeRepository from "../repository/memberTypeRepository";

const MemberTypeDelete = props  => {
    const [formData] = React.useState({
        id:props.id,

    });
    const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const submitForm = (e) => {
        e.preventDefault();
        const id = formData.id;
        memberTypeRepository.deleteMemberType(id).then(res=>{
            window.location="/memberTypes";
        }).catch((error)=>{
            if(error.message==="Request failed with status code 500"){
                timeoutErrorMessage();
            }
        })
        
    }
    const timeoutErrorMessage = () => {
        let timer;
        setShowErrorMessage(true);
        setErrorMessage("You can not delete that member type");
        if (clearPreviousTimeout !== undefined) {
          clearTimeout(clearPreviousTimeout);
        }
        timer = setTimeout(() => {
          setShowErrorMessage(false);
        }, 3000);
        setClearPreviousTimeout(timer);
      };
    const handleCancel = () => {
        props.handleCloseDelete();
    }
    if (localStorage.getItem("email")!==null){
        return (
            <div className="container">

                <div className="row mt-5" >
                    <div className="col mainCol">
                        <Card style={{marginTop:"10%"}}><CardBody>
                            <h3 className="formHeader">Delete Member Type?</h3>
                            <form onSubmit={submitForm}>
                                <div className="form-group">
                                    Are you sure you want to delete member type?
                                </div>
                                <button type="submit" id="submit" className="btn dangerBtn" onClick={submitForm}>Delete</button> <br/>
                            </form>
                            <button style={{marginTop:10}} type="button" className="btn dangerCancelBtn" onClick={handleCancel}>Cancel</button>
                        </CardBody>
                        </Card>
                    </div>
                </div>
                {showErrorMessage === true ? (
          <div
            style={{
              width: "500px",
              position: "absolute",
              top: "40px",
              right: "2px",
            }}
            className="alert alert-danger alert-dismissible fade show mt-3"
            role="alert"
          >
            {errorMessage}
          </div>
        ) : (
          <div></div>
        )}
            </div>
        );}
    else {
        return (
            <Login/>
        )
    }
};

export default MemberTypeDelete;
