import React from "react";
import {Card} from "@material-ui/core";
import {CardBody} from "reactstrap";

const Login = props => {
    return (
        <div className="container" style={{display: "grid", marginTop: "20%"}}>
            <div className="row mt-5">

                <div className="col">
                    <Card><CardBody className="customCard">
                        <div style={{textAlign:"center"}}>By clicking on the button bellow you are going to be redirected to the UKIM login service,
                            where you need to provide the right parameters in order to access the functionalities of
                            this domain.
                        </div>
                        <form style={{marginLeft: "45%", marginTop: "1%"}}>
                            <div className="form-group">
                                <a className="btn btn-sm infoBtn" href="/oidc/login">Login</a>
                            </div>
                        </form>
                    </CardBody></Card>
                </div>
            </div>
        </div>
    )
}
export default Login;