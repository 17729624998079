import React from 'react';
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {AiFillDelete} from "react-icons/ai";
import UserAdd from "./UserAdd";
import {IoMdAdd} from "react-icons/io";
import UserDelete from "./UserDelete";
import RoleAdd from "../Roles/RoleAdd";
import {RiEdit2Fill} from "react-icons/ri";
import UserEdit from "./UserEdit";
import Login from "../Login/Login";

const UsersAll = props => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [openAdd, setOpenAdd] = React.useState(false);

    const [openEdit, setOpenEdit] = React.useState(false);
    const [firstName, setFirstName] = React.useState();
    const [lastName, setLastName] = React.useState();
    const [email, setEmail] = React.useState();
    const [username, setUsername] = React.useState();
    const [role, setRole] = React.useState();
    const [id, setId] = React.useState();
    const handleOpenEdit = (e) => {
        const value = e.target.value;
        const json = JSON.parse(value);
     
        const id = e.target.id;
      
        setFirstName(json.firstName);
        setLastName(json.lastName);
        setEmail(json.email);
        setUsername(json.username);
        setRole(json.role);
        setId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {

        setOpenEdit(false);
    };

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };


    const [openDelete, setOpenDelete] = React.useState(false);


    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };


    const bodyUserAdd = (
        <UserAdd handleCloseAdd={handleCloseAdd} roles={props.roles}></UserAdd>

    )
    const bodyUserEdit = (

        <UserEdit  handleCloseEdit={handleCloseEdit} email={email} firstName={firstName} lastName={lastName} username={username} id={id} roles={props.roles} role={role}></UserEdit>

    )

    const bodyUserDelete = (
        <UserDelete handleCloseDelete={handleCloseDelete} comesFrom={"parent"} id={id}></UserDelete>

    )
    const [filteredUsers, setFilteredUsers] = React.useState([]);
    const filterTable = (e) => {
        const value = e.target.value;
     

        if (value === null || value === "") {
            setFilteredUsers(props.users);
        } else {
            let uniList = []
            props.users.map(uni => {
                if (uni.firstName.toLowerCase().includes(value.toLowerCase()) || uni.lastName.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setFilteredUsers(uniList);
            } else {
                setFilteredUsers(props.users);
            }
        }
    }
  
    const [openAddRole, setOpenAddRole] = React.useState(false);
    const handleOpenAddRole = () => {
        setOpenAddRole(true);
    }
    const handleCloseAddRole = () => {
        setOpenAddRole(false);
    }
    const addRoleBody = (
        <RoleAdd handleCloseAddRole={handleCloseAddRole}></RoleAdd>
    );
    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyUserAdd}

            </Modal>
            <Modal open={openEdit}
                   onClose={handleCloseEdit}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                {bodyUserEdit}
            </Modal>
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyUserDelete}
            </Modal>
            <Modal
                open={openAddRole}
                onClose={handleCloseAddRole}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {addRoleBody}
            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard">
                        <CardHeader title={"Users"}></CardHeader>
                        <CardBody>
                            <button style={{marginBottom: 20, float: "right"}} className="btn saveBtn"
                                    onClick={handleOpenAddRole}>Create user role
                            </button>
                            <br/>
                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>


                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">First Name</TableCell>
                                                    <TableCell data-priority="3">Last Name</TableCell>
                                                    <TableCell data-priority="3">Email</TableCell>
                                                    
                                                    <TableCell data-priority="3">Role</TableCell>
                                                    <TableCell data-priority="3">
                                                        <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                            <IoMdAdd
                                                                className="buttonIcon"/>
                                                        </a>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody id="userTableBody">
                                                {filteredUsers.length > 0 ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {

                                                    const userEdit = JSON.stringify({
                                                        "firstName": term.firstName,
                                                        "lastName": term.lastName,
                                                        "email": term.email,
                                                        
                                                        "role": term.role
                                                    });

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell
                                                                className="innerCell">{term.firstName}</TableCell>
                                                            <TableCell className="innerCell">{term.lastName}</TableCell>
                                                            <TableCell className="innerCell">{term.email}</TableCell>
                                                            
                                                            <TableCell
                                                                className="innerCell">{term.role.name === "ROLE_ADMIN" ? "Admin" : term.role.name === "ROLE_USER" ? "User" : term.role.name}</TableCell>
                                                            <TableCell>
                                                                <button id={term.id} value={userEdit}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenEdit}><RiEdit2Fill
                                                                    className="buttonIcon"/></button>
                                                                <button value={term.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}> Delete<AiFillDelete
                                                                    className="buttonIcon"/></button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }) : props.users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                        const userEdit = JSON.stringify({
                                                            "firstName": term.firstName,
                                                            "lastName": term.lastName,
                                                            "email": term.email,
                                                            
                                                            "role": term.role
                                                        });

                                                        return (
                                                            <TableRow className="innerRow">
                                                                <TableCell
                                                                    className="innerCell">{term.firstName}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.lastName}</TableCell>
                                                                <TableCell
                                                                    className="innerCell">{term.email}</TableCell>
                                                               
                                                                <TableCell
                                                                    className="innerCell">{term.role.name === "ROLE_ADMIN" ? "Admin" : term.role.name === "ROLE_USER" ? "User" : term.role.name === "ROLE_FINANCE" ? "Finance" : term.role.name}</TableCell>
                                                                <TableCell>
                                                                    <button id={term.id} value={userEdit}
                                                                            className="btn btn-sm infoBtn"
                                                                            onClick={handleOpenEdit}><RiEdit2Fill
                                                                        className="buttonIcon"/></button>
                                                                    <button value={term.id} className="btn btn-sm infoBtn"
                                                                            onClick={handleOpenDelete}><AiFillDelete
                                                                        className="buttonIcon"/> Delete
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }
                                                )}

                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={filteredUsers.length > 0 ? filteredUsers.length : props.users.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>

        </div>
    );}
    else{
        return(
            <Login/>
        )
    }
};


export default UsersAll;