import React from 'react';
import 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/general.css';
import {Card, CardBody} from "reactstrap";
import '../../assets/general.css';
import TimesheetService from "../../repository/timesheetsRepository";
import Login from "../Login/Login";

const UniversityAdd = (props) => {
    const [formData, updateFormData] = React.useState({
        name: "",
        dean: ""
    });


    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }


    const submitForm = (e) => {
        e.preventDefault();
        const name = formData.name;
        const dean = formData.dean;
      
        TimesheetService.addUniversity(name,dean);
        window.location="/universities";
    }

    const handleCancel = () => {
        props.handleCloseAdd();
    }
    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">

            <div className="row mt-5" >
                <Card style={{marginLeft:"20%",marginTop:"10%"}}><CardBody>
                    <h3 className="formHeader">Add Institution</h3>
                <div className="col">

                    <form onSubmit={submitForm}>
                        <div className="form-group">

                            <label htmlFor="name">Name</label>
                            <input type="text"
                                   className="form-control inputForm"
                                   name="name" required id="name"
                                   onChange={handleChange}

                            />
                        </div>
                        <div className="form-group">

                            <label htmlFor="dean">Dean</label>
                            <input type="text" className="form-control inputForm" id="dean" name="dean"
                                   required
                                   onChange={handleChange}/>


                        </div>
                        <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>

                    </form>
                    <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                </div>
                </CardBody></Card>
            </div>
        </div>

    )}
    else{
        return (<Login/>)
    }

}
export default UniversityAdd;

