import React from 'react';
import {Card, CardBody} from "reactstrap";
import projectRepository from "../../repository/projectRepository";
import Login from "../Login/Login";



const ProjectDelete = props => {
    const [formData] = React.useState({
        id:props.projectId,

        });

    const submitForm = (e) => {
        e.preventDefault();
        const id = formData.id;
        projectRepository.deleteProject(id);
        window.location="/projects";
    }
    const handleCancel = () => {
        props.handleCloseDelete();
    }
    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">

            <div className="row mt-5" >
                <div className="col mainCol">
                <Card style={{marginTop:"10%"}}><CardBody>
                    <h3 className="formHeader">Delete Project</h3>
                        <form onSubmit={submitForm}>
                            <div className="form-group">
                                Are you sure you want to delete the project?
                            </div>
                            <button type="submit" id="submit" className="btn dangerBtn">Delete</button> <br/>
                        </form>
                    <button style={{marginTop:10}} className="btn dangerCancelBtn" type="button" onClick={handleCancel}>Cancel</button>
                </CardBody>
                </Card>
            </div>
            </div>
        </div>
    );}else{
        return (<Login/>)
    }
};



export default ProjectDelete;