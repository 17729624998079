import instanceA from "../custom-axios/instanceA";

const MemberTypeService= {
    fetchMemberTypes:()=>{
        return instanceA.get("/memberTypes");
    },
    addMemberType: (name) =>{
        return instanceA.post("/memberTypes/add",{
            "name": name
        });
    },
    deleteMemberType:(id)=>{
        return instanceA.delete(`/memberTypes/delete/${id}`)
    },
    editMemberType: (memberTypeHelper) =>{
        return instanceA.put("/memberTypes/edit", memberTypeHelper,{
            headers:{
                'Content-Type': 'application/json',
            }
        })
    },

}

export default MemberTypeService;