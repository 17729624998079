import {Card, CardBody} from "reactstrap";
import React from "react";
import TimesheetService from "../../repository/timesheetsRepository";
import Login from "../Login/Login";

const UniversityEdit = (props) => {
    const [formData, updateFormData] = React.useState({
        id:props.uniId,
        name: props.uniName,
        dean: props.uniDean,
    });

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }


    const submitForm = (e) => {
        e.preventDefault();
        const name = formData.name;
        const dean = formData.dean;
        const id = formData.id;
       
        TimesheetService.editUniversity(id,name,dean);
        window.location="/universities";
    }

    const handleCancel = () => {
        props.handleCloseEdit();
    }

    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">

            <div className="row mt-5" >
                <div className="col mainCol">
                <Card style={{marginTop:"10%"}}>
                    <CardBody>
                    <h3 className="formHeader">Edit Institution</h3>
                        <form onSubmit={submitForm}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text"
                                       className="form-control inputForm"
                                       name="name" required id="name"
                                       onChange={handleChange}
                                       defaultValue={props.uniName}
                                />
                            </div>
                            <div className="form-group">

                                <label htmlFor="dean">Dean</label>
                                <input type="text" className="form-control inputForm" id="dean" name="dean"
                                       required
                                       onChange={handleChange}
                                       defaultValue={props.uniDean}
                                />


                            </div>
                            <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>
                        </form>
                        <button style={{marginTop:10}} className="btn saveBtn" onClick={handleCancel} type="button">Cancel</button>
                </CardBody>
                </Card>
            </div>
            </div>
        </div>

    )}else{
        return (
            <Login/>
        )
    }

}

export default UniversityEdit;