import React from "react";
import '../../assets/general.css'
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {IoMdAdd} from "react-icons/io";
import {AiFillDelete} from "react-icons/ai";
import ProjectAdd from "../Projects/ProjectAdd";
import ProjectDelete from "../Projects/ProjectDelete";
import Login from "../Login/Login";


const UniversityDetails = (props) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [openAdd, setOpenAdd] = React.useState(false);
    const [id, setId] = React.useState();
    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const [openDelete, setOpenDelete] = React.useState(false);

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };

    const bodyProjectAdd = (
        <ProjectAdd universities={props.universities} handleCloseAdd={handleCloseAdd}></ProjectAdd>
    );

    const bodyDelete = (
        <ProjectDelete projectId={id}></ProjectDelete>
    )
    const [filteredProjects, setFilteredProjects] = React.useState([]);
    const filterTable = (e) => {
        const value = e.target.value;
      

        if (value === null || value === "") {
            setFilteredProjects(props.projects);
        } else {
            let uniList = []
            props.projects.map(uni => {
                if (uni.name.toLowerCase().includes(value.toLowerCase()) || uni.projectNumber.toLowerCase().includes(value.toLowerCase()) || uni.university.name.toLowerCase().includes(value.toLowerCase()) || uni.estimatedBudget.toString().toLowerCase().includes(value.toLowerCase())
                    || uni.startDate.toLowerCase().includes(value.toLowerCase()) || uni.endDate.toLowerCase().includes(value.toLowerCase()) || uni.approved.toString().toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setFilteredProjects(uniList);
            } else {
                setFilteredProjects(props.projects);
            }
        }
    }
    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyProjectAdd}

            </Modal>
            <Modal open={openDelete}
                   onClose={handleCloseDelete}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                {bodyDelete}
            </Modal>
            <div className="row mt-5">
                <div className="col">
                    <Card className="customCard">
                        <CardHeader title={"Projects"}></CardHeader>
                        <CardBody>
                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Project Name</TableCell>
                                                    <TableCell data-priority="3">Project Number</TableCell>
                                                    <TableCell data-priority="3">Institution</TableCell>
                                                    <TableCell data-priority="3">Project Manager</TableCell>
                                                    <TableCell data-priority="3">Estimated Budget</TableCell>
                                                    <TableCell data-priority="3">Start Date</TableCell>
                                                    <TableCell data-priority="3">End Date</TableCell>
                                                    <TableCell data-priority="3">Approved</TableCell>
                                                    <TableCell data-priority="3">
                                                        <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                            <IoMdAdd
                                                                className="buttonIcon"/>
                                                        </a></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredProjects.length > 0 ? filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{term.name}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.projectNumber}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.university.name}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.projectManager}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.estimatedBudget}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.startDate}</TableCell>
                                                            <TableCell className="innerCell">{term.endDate}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.approved ? "yes" : "no"}</TableCell>
                                                            <TableCell>

                                                                <button value={term.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );


                                                }) : props.projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{term.name}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.projectNumber}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.university.name}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.projectManager}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.estimatedBudget}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.startDate}</TableCell>
                                                            <TableCell className="innerCell">{term.endDate}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{term.approved ? "yes" : "no"}</TableCell>
                                                            <TableCell>

                                                                <button value={term.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}

                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={filteredProjects.length>0?filteredProjects.length:props.projects.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />

                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>

        </div>
    )}else{
        return (
            <Login/>
        )
    }
}

export default UniversityDetails;