import React from 'react';
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {AiFillDelete} from "react-icons/ai";
import {IoMdAdd} from "react-icons/io";
import MemberAdd from "./MemberAdd";
import MemberDelete from "./MemberDelete";

const MemberAll = props => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [openAdd, setOpenAdd] = React.useState(false);

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };


    const [openDelete, setOpenDelete] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };
   
    const handleCloseDelete = () => {

        setOpenDelete(false);
    };


    const bodyMemberAdd =(
        <MemberAdd memberType={props.memberType} users={props.users} projects={props.projects} projectId={props.projectId}></MemberAdd>

    )

    const bodyMemberDelete =(
        <MemberDelete handleCloseDelete={handleCloseDelete} comesFrom={"parent"} id={id}></MemberDelete>

    )
    const [filteredMembers, setFilteredMembers] = React.useState([]);
    const filterTable = (e) => {
        const value = e.target.value;
       

        if (value === null || value === "") {
            setFilteredMembers(props.members);
        } else {
            let uniList = []
            props.members.map(uni => {
                if (uni.user.firstName.toLowerCase().includes(value.toLowerCase()) || uni.user.lastName.toLowerCase().includes(value.toLowerCase()) ||
                    uni.memberType.name.toLowerCase().includes(value.toLowerCase()) || uni.project.name.toLowerCase().includes(value.toLowerCase()) ||
                    uni.hourlyRate.toString().toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setFilteredMembers(uniList);
            } else {
                setFilteredMembers(props.members);
            }
        }
    }
    console.log(props)
    return (
        <div className="container" style={{display:"grid"}}>
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyMemberAdd}

            </Modal>
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyMemberDelete}
            </Modal>
            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard">
                        <CardHeader title={"Members"}></CardHeader>
                        <CardBody>
                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>


                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Name and Surname</TableCell>
                                                    <TableCell data-priority="3">Position Type</TableCell>
                                                    <TableCell data-priority="3">Project</TableCell>
                                                    <TableCell data-priority="3">Hourly Rate</TableCell>
                                                    <TableCell data-priority="3">
                                                        <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                            <IoMdAdd
                                                                className="buttonIcon"/>
                                                        </a></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody id="memberTableBody">
                                                {filteredMembers.length>0?filteredMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term)=>{
                                                    return(
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{term.user.firstName} {term.user.lastName}</TableCell>
                                                            <TableCell className="innerCell">{term.memberType.name}</TableCell>
                                                            <TableCell className="innerCell">{term.project.name}</TableCell>
                                                            <TableCell className="innerCell">{term.hourlyRate}</TableCell>
                                                            <TableCell>
                                                                {/*    <button id={term.id}*/}
                                                                {/*            className="btn btn-sm infoBtn"*/}
                                                                {/*            onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                                {/*        className="buttonIcon"/></button>*/}
                                                                <button value={term.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/></button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}):props.members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{term.user.firstName} {term.user.lastName}</TableCell>
                                                            <TableCell className="innerCell">{term.memberType.name}</TableCell>
                                                            <TableCell className="innerCell">{term.project.name}</TableCell>
                                                            <TableCell className="innerCell">{term.hourlyRate}</TableCell>
                                                            <TableCell>
                                                            {/*    <button id={term.id}*/}
                                                            {/*            className="btn btn-sm infoBtn"*/}
                                                            {/*            onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                            {/*        className="buttonIcon"/></button>*/}
                                                                <button value={term.id} className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/></button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}

                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={filteredMembers.length>0?filteredMembers.length:props.members.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>

        </div>
    );
};



export default MemberAll;