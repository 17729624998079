import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {IoMdAdd} from "react-icons/io";
import {BsInfo} from "react-icons/bs";
import {AiFillDelete} from "react-icons/ai";
import React, {useState} from "react";
import ItemAdd from "./ItemAdd";
import ItemDelete from "./ItemDelete";
import projectRepository from "../../repository/projectRepository";
import {useHistory} from 'react-router-dom';



const ItemsAll = (props) =>{
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [filteredItems, setItems] = useState([]);

    const filterTable = (e) => {
        const value = e.target.value;
      

        if (value === null || value === "") {
            setItems(props.location.state.items);
        } else {
            let uniList = []
            props.location.state.items.map(uni => {
                if (uni.maxHours.toString().toLowerCase().includes(value.toLowerCase()) || uni.dateStart.toLowerCase().includes(value.toLowerCase()) || uni.dateEnd.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setItems(uniList);
            } else {
                setItems(props.location.state.items);
            }
        }
    }

    const [id, setId] = React.useState();
    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpenAdd = () => {
        setOpenAdd(true);
    };


    const handleCloseAdd = () => {
        setOpenAdd(false);
    };
    const [openDelete, setOpenDelete] = React.useState(false);

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };

    const handleClick = (e) =>{

        const id = e.target.id;
        projectRepository.getItemHoursByItem(id).then((snapshot)=>{
            if (snapshot.data.length > 0){
                history.push({
                    pathname: '/itemHours/item/' + id,
                    state:{
                        itemHours:snapshot.data,
                        itemId:id
                    }
                });}else{
                history.push({
                    pathname: '/itemHours/item/' + id,
                    state:{
                        itemHours:[],
                        itemId:id
                    }
                })
            }
        });

    }
    const bodyAdd = (
        <ItemAdd taskId={props.location.state.taskId}></ItemAdd>
    )
    const bodyDelete = (
        <ItemDelete id={id} taskId={props.location.state.taskId}></ItemDelete>

    )


    return (
        <div className="container">
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyAdd}

            </Modal>

            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyDelete}

            </Modal>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard">
                        <CardHeader title={"Items"}></CardHeader>
                        <CardBody>

                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            {/*<TextField id="standard-basic" label="Filter" onKeyPress={filterTable}/>*/}

                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Description</TableCell>
                                                    <TableCell data-priority="3">Maximum hours</TableCell>
                                                    <TableCell data-priority="3">Start date</TableCell>
                                                    <TableCell data-priority="3">End date</TableCell>
                                                    <TableCell data-priority="3">
                                                        <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                            <IoMdAdd
                                                                className="buttonIcon"/>
                                                        </a></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody id="universityTableBody">

                                                {filteredItems.length > 0 ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.task.description}</TableCell>
                                                            <TableCell className="innerCell">{uni.maxHours}</TableCell>
                                                            <TableCell className="innerCell">{uni.dateStart}</TableCell>
                                                            <TableCell className="innerCell">{uni.dateEnd}</TableCell>
                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn">
                                                                    <BsInfo
                                                                        className="buttonIcon"/></button>
                                                                {/*<button id={uni.id} value={nameAndDean}*/}
                                                                {/*        className="btn btn-sm infoBtn"*/}
                                                                {/*        onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                                {/*    className="buttonIcon"/></button>*/}
                                                                <button value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/></button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }) : props.location.state.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.task.description}</TableCell>
                                                            <TableCell className="innerCell">{uni.maxHours}</TableCell>
                                                            <TableCell className="innerCell">{uni.dateStart}</TableCell>
                                                            <TableCell className="innerCell">{uni.dateEnd}</TableCell>

                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn">
                                                                    <BsInfo
                                                                        className="buttonIcon"/></button>
                                                                {/*<button id={uni.id} value={nameAndDean}*/}
                                                                {/*        className="btn btn-sm infoBtn"*/}
                                                                {/*        onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                                {/*    className="buttonIcon"/></button>*/}
                                                                <button value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/></button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={filteredItems.length>0?filteredItems.length:props.location.state.items.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />

                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>
        </div>
    )
}
export default ItemsAll;