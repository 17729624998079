import React from 'react';
import {Card, CardBody} from "reactstrap";
import holidaysRepository from "../../repository/holidaysRepository";
import Login from "../Login/Login";


const HolidaysAdd = props => {

    const [formData, updateForDat] = React.useState({
        name: "",
        date: "",
    })

    const handleChange = (e) =>{
        updateForDat({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }
    const formatDate = (date) => {
        const split = date.split("-");
        return split[2] + "." + split[1] + "." + split[0];
    }
    const onFormSubmit = (e) => {
        e.preventDefault();
        const name = formData.name;
        const date = formatDate(formData.date);
       
        holidaysRepository.addHoliday(name, date).then(() => {
            window.location = "/holidays";
        })



    }

    const handleCancel = () =>{
        props.handleCloseAdd();
    }
    if (localStorage.getItem("email")!==null){
    return (
        <div className="container">

            <div className="row mt-5" >
                <Card style={{marginLeft:"20%",marginTop:"10%"}}><CardBody>
                    <h3 className="formHeader">Add Holiday</h3>
                    <div className="col">

                        <form onSubmit={onFormSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control inputForm" id="name"
                                       name="name"  required
                                       onChange={handleChange}/>


                            </div>
                            <div className="form-group">
                                <label htmlFor="date">Date</label>
                                <input required type="date" className="form-control inputForm" id="date"
                                       name="date"
                                       onChange={handleChange}/>


                            </div>
                            <button type="submit" id="submit" className="btn saveBtn">Save</button>

                        </form>
                        <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                    </div>
                </CardBody></Card>
            </div>
        </div>
    );}else{
        return (
            <Login/>
        )
    }
};



export default HolidaysAdd;

