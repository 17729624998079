import { Card, CardBody } from "reactstrap";
import React, { useEffect } from "react";
import membersRepository from "../../repository/membersRepository";
import Login from "../Login/Login";
import Select from "react-select";
import MemberRepository from "../../repository/membersRepository";
import { useHistory } from "react-router-dom";
import ProjectService from "../../repository/projectRepository";
import MemberTypeService from "../../repository/memberTypeRepository";

const TaskAssignMembers = (props) => {
  let history = useHistory();
  const [fields, setFields] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [projectMembers, setProjectMembers] = React.useState([]);
  const assignUser = (memberIds, taskId) => {
    membersRepository.assignUsersToTask(memberIds, taskId);
  };
  const [taskMemberHelper, setTaskMemberHelper] = React.useState({
    taskId: props.taskId,
    memberIds: [],
  });
  const [selectedMember, setSelectedMember] = React.useState(null);
  const handleChangeMemberIds = (selected) => {
    setSelectedMember(selected.value);
    let listMemberIds = [];
    for (var i = 0; i < selected.length; i++) {
      listMemberIds.push(selected[i].value);
    }
    setTaskMemberHelper({
      ...taskMemberHelper,
      memberIds: listMemberIds,
    });
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    const taskHelper = {
      taskId: taskMemberHelper.taskId,
      memberIds: taskMemberHelper.memberIds,
    };
    // const users = document.querySelectorAll('select[name="user"]');

    // let done = 0;
    // for (var i = 0; i < users.length; i++) {
    //     const user = users[i].value;

    //     await membersRepository.assignUsersToTask(user,props.taskId).then(()=>{
    //         done = done + 1;
    //         if (done === users.length) {
    //             window.location = "/work_packages/tasks/"+ props.workPackageId;
    //         }
    //     });
    // }
    ProjectService.addMemberToTask(taskHelper).then((res) => {
      console.log(res.data);
      window.location = "/work_packages/tasks/" + props.workPackageId;
    });
  };

  let usersOptions = [];

  if (filteredUsers.length > 0) {
    filteredUsers.map((user) => {
      usersOptions.push({
        value: user.id,
        label:
          user.user.firstName +
          " " +
          user.user.lastName +
          " (" +
          user.user.email +
          ") ",
      });
    });
  } else {
    props.users.map((user) => {
      usersOptions.push({
        value: user.id,
        label:
          user.user.firstName +
          " " +
          user.user.lastName +
          " (" +
          user.user.email +
          ") ",
      });
    });
  }

  const handleCancel = () => {
    props.handleCloseAddMember();
  };

  const addMemberField = (e) => {
    let newFields = [...fields];
    newFields.push(1);
    setFields(newFields);
    const users = document.querySelectorAll('select[name="user"]');
    // if(users.length>0){
    //     const value = users[users.length-1].value;
    //     membersRepository.getMembersByUserNot(value).then((snapshot)=>{
    //         console.log("XS: ",snapshot.data)
    //         setFilteredUsers(snapshot.data);
    //     });}
    setFilteredUsers(props.users);
    document.querySelector("#submit").style.visibility = "visible";
    document.querySelector("#cBody").style.height = "500px";
  };
  const getAllMembersByProject = () => {
    let projectId;
    for (var i = 0; i < window.history.state.state.tasks.length; i++) {
      projectId = window.history.state.state.tasks[i].workPackage.project.id;
    }
    MemberRepository.findAllMembersByProject(projectId).then((res) => {
      console.log(res.data);
      setProjectMembers(res.data);
    });
  };
  useEffect(() => {
    console.log(props);
    getAllMembersByProject();
  }, []);
  if (localStorage.getItem("email") !== null) {
    const optionsMembers = projectMembers.map((member) => ({
      value: member.id,
      label:
        member.user.firstName +
        " " +
        member.user.lastName +
        " (" +
        member.user.email +
        ") ",
    }));
    return (
      <div className="container">
        <div className="row mt-5">
          <Card style={{ marginLeft: "20%", marginTop: "10%" }}>
            <CardBody id="cBody" style={{ overflowY: "scroll" }}>
              <h3 className="formHeader">Add Member</h3>
              <div className="col formMember">
                {/*<button style={{marginBottom: 10}} type="button" id="addMember" className="btn saveBtn"*/}
                {/*        onClick={addMemberField}>Add*/}
                {/*    Member*/}
                {/*</button>*/}

                <form onSubmit={onFormSubmit}>
                  {/* {fields.map((f) => { */}
                  <div
                    style={{
                      borderBottom: "1px solid #8e8e8e",
                      marginBottom: 10,
                    }}
                  >
                    <div className="form-group">
                      <label>User</label>
                      <Select
                        name="user"
                        id="reactSelectUsers"
                        className="users"
                        onChange={handleChangeMemberIds}
                        value={selectedMember}
                        isMulti
                        options={optionsMembers}
                      ></Select>
                      {/* <select required name="user" className="form-control sm" multiple>
                                                    <option selected>---------</option>
                                                    {filteredUsers.length>0?filteredUsers.map((term)=>
                                                        <option id={term.id} value={term.id}>{term.user.firstName} {term.user.lastName} ({term.user.email})</option>
                                                    ):props.users.map((term) =>
                                                        <option id={term.id} value={term.id}>{term.user.firstName}  {term.user.lastName} ({term.user.email})</option>
                                                    )}
                                                </select> */}
                    </div>
                  </div>
                  {/* ) */}
                  {/* } */}
                  {/* )} */}
                  {/* <button style={{marginBottom: 10, marginLeft: "20%"}} type="button" id="addMember" className="btn addMemberBtn"
                                        onClick={addMemberField}>Add
                                    Member
                                </button> */}
                  <button
                    type="submit"
                    id="submit"
                    className="btn saveBtn"
                    onClick={onFormSubmit}
                  >
                    Save
                  </button>
                  <br /> <br />
                  <button
                    type="submit"
                    id="submit"
                    className="btn saveBtn"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <br />
                </form>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  } else {
    return <Login />;
  }
};
export default TaskAssignMembers;
