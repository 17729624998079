import axios from "../custom-axios/axios";
import instanceA from "../custom-axios/instanceA";

const ProjectService = {
    fetchProjects: () => {
        return instanceA.get("/projects");
    },

    addProject: (name, projectNumber, estimatedBudget, startDate, endDate, university, approved) => {
        return instanceA.post("/projects/add", {
            "name": name,
            "projectNumber": projectNumber,
            "estimatedBudget": estimatedBudget,
            "startDate": startDate,
            "endDate": endDate,
            "university": university,
            "approved": approved
        });
    },
    editProject: (id, name, projectNumber, estimatedBudget, startDate, endDate, university, approved)=>{
        return instanceA.put(`/projects/edit/${id}`,{
            "id" : id,
            "name" : name,
            "projectNumber" : projectNumber,
            "estimatedBudget" : estimatedBudget,
            "startDate": startDate,
            "endDate": endDate,
            "university" : university,
            "approved" : approved
        });
    },
    getUniversity: (id) => {
        return instanceA.get("/universities/" + id);
    },
    deleteProject: (id) => {
        return instanceA.delete(`/projects/delete/${id}`);
    },
    getOneTask: (id) => {
        return instanceA.get(`/tasks/${id}`);
    },
    getTasksByWorkPackage: (id) => {
        return instanceA.get(`/tasks/workPackage/${id}`);
    },
    getItemHoursByTask: (id) => {
        return instanceA.get(`/itemHours/task/${id}`);
    },
    getOneItem:(id)=>{
        return instanceA.get(`/items/${id}`);
    },
    addItem: (maxHours, dateStart, dateEnd, task) => {
        return instanceA.post(`/items/`, {
            maxHours: maxHours, dateEnd: dateEnd, dateStart: dateStart, task: task
        });
    },
    deleteItem: (id) => {
        return instanceA.post(`/items/delete/${id}`);
    },

    getItemHoursByItem: (id) => {
        return instanceA.get(`/itemHours/item/${id}`);
    },
    addItemHour:(day,hours,item)=>{
        return instanceA.post(`/itemHours/`,{day:day,hours:hours,task:item});
    },
    getItemHoursByMemberTaskTimeSheet:(memberId, taskId, timesheetId)=>{
        return instanceA.get(`/itemHours/findByMemberTaskTimeSheet/${memberId}/${taskId}/${timesheetId}`);
    },

    getUserAuth:()=>{
        return instanceA.get(`/authUser`);
    },
    addMemberToProject:(projectUserHelper)=>{
        return instanceA.post(`/projects/addMember`, projectUserHelper,{headers:{
            'Content-Type':'application/json'
        }})
    },
    addMemberToTask:(taskMemberHelper)=>{
        return instanceA.post(`tasks/addMember`, taskMemberHelper,{headers:{
            'Content-Type':'application/json'
        }})
    },
    deleteMemberFromProject:(projectMembersHelper)=>{
        return instanceA.post(`projects/delete`,projectMembersHelper)
    },
    getMemberByProject:(projectMemberHelper)=>{
        return instanceA.post(`projects/getMemberRolePerProject`, projectMemberHelper);
    }



}

export default ProjectService;