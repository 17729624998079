import {useHistory} from "react-router-dom";
import React from "react";
import TimesheetService from "../../repository/timesheetsRepository";
import projectRepository from "../../repository/projectRepository";
import {Card, CardBody} from "reactstrap";

const ItemDelete = (props) => {
    const history = useHistory();

    const [formData] = React.useState({
        id:props.id,
        taskId:props.taskId,

    });

    const submitForm = (e) => {
        e.preventDefault();
        const id = formData.id;
        const taskId = formData.taskId;
        projectRepository.deleteItem(id).then(()=>{
            projectRepository.getItemsByTask(taskId).then((snapshot)=>{
                if (snapshot.data.length > 0){
                    history.push({
                        pathname: '/items/task/' + taskId,
                        state:{
                            items:snapshot.data,
                            taskId:taskId
                        }
                    });
                    history.go(0)
                    // window.location="/items/task/"+taskId;
                }else{
                    history.push({
                        pathname: '/items/task/' + taskId,
                        state:{
                            items:[],
                            taskId:taskId
                        }
                    })
                    history.go(0)
                    // window.location="/items/task/"+taskId;
                }
            });
        });

    }
    return(
        <div className="container">

            <div className="row mt-5" >
                <div className="col mainCol">
                <Card style={{marginTop:"10%"}}>
                    <CardBody>
                    <h3 className="formHeader">Delete Item</h3>
                        <form onSubmit={submitForm}>
                            <div className="form-group">
                                Are you sure you want to delete the item?
                            </div>
                            <button type="submit" id="submit" className="btn dangerBtn">Delete</button> <br/>
                        </form>
                </CardBody>
                </Card>
                </div>
            </div>
        </div>
    )
}
export default ItemDelete;