import {Card, CardBody} from "reactstrap";
import React from "react";
import membersRepository from "../../repository/membersRepository";
import Login from "../Login/Login";

const UniversityDelete = (props) => {
    const [formData] = React.useState({
        id:props.id,

    });

    const submitForm = (e) => {
        e.preventDefault();
        const id = formData.id;
        membersRepository.deleteUser(id);
        window.location="/users";
    }

    const handleCancel = () =>{
        props.handleCloseDelete();
    }
    if(localStorage.getItem("email")!==null){
    return(
        <div className="container">
            <div className="row mt-5" >
                <div className="col mainCol">
                <Card style={{marginTop:"10%"}}><CardBody>
                    <h3 className="formHeader">Delete User</h3>
                        <form onSubmit={submitForm}>
                            <div className="form-group">
                                Are you sure you want to delete user?
                            </div>
                            <button type="submit" id="submit" className="btn dangerBtn" onClick={submitForm}>Delete</button> <br/>
                        </form>
                    <button style={{marginTop:10}} type="button" className="btn dangerCancelBtn" onClick={handleCancel}>Cancel</button>
                </CardBody>
                </Card>
            </div>
            </div>
        </div>
    )}else {
        return (
            <Login/>
        )
    }
}

export default UniversityDelete;