import React from "react";
import TimesheetService from "../../repository/timesheetsRepository";
import {Card, CardBody} from "reactstrap";
import projectRepository from "../../repository/projectRepository";
import {useHistory} from 'react-router-dom'
import Login from "../Login/Login";
const TaskDelete = (props) => {
    const history = useHistory();
    const [message, setErrorMessage] = React.useState("");
    const [error, setError] = React.useState(false);
    const [formData] = React.useState({
        id:props.id,
        workPackageId:props.workPackageId,

    });

    const handleCancel = () => {
        props.handleCloseDelete();
    }

    const submitForm = (e) => {
        e.preventDefault();
        const id = formData.id;
        const workProjectId = formData.workPackageId;   
        projectRepository.getItemHoursByTask(id).then((r)=>{
            if(r.data.length === 0) 
            {
                TimesheetService.deleteTask(id).then(()=>{
                    projectRepository.getTasksByWorkPackage(workProjectId).then((snapshot)=>{
                        if (snapshot.data.length > 0){
                            history.push({
                                pathname: '/work_packages/tasks/' + workProjectId,
                                state:{
                                    tasks:snapshot.data,
                                    workPackageId:workProjectId
                                }
                            });
                            window.location="/work_packages/tasks/"+workProjectId;
                        }else{
                            history.push({
                                pathname: '/work_packages/tasks/' + workProjectId,
                                state:{
                                    tasks:[],
                                    workPackageId:workProjectId
                                }
                            })
                            window.location="/work_packages/tasks/"+workProjectId;
                        }
                    });
                });
            } else {
                setError(true);
                setErrorMessage("You can not delete this task, there are hours for this task in a timesheet as a part of this project!");
            }
        });
        

    }
    if(localStorage.getItem("email")!==null){
    return(
        <div className="container">

            <div className="row mt-5" >
                <div className="col mainCol">
                <Card style={{marginTop:"10%"}}><CardBody>
                    <h3 className="formHeader">Delete Task</h3>
                        <form onSubmit={submitForm}>
                            <div className="form-group">
                                Are you sure you want to delete the task?
                            </div>
                            <button type="submit" id="submit" className="btn dangerBtn">Delete</button> <br/>
                            <br/>
                            <button type="submit" id="submit" className="btn dangerCancelBtn" onClick={handleCancel}>Cancel</button> <br/>
                            {error === true ?
                                <div style={{width:"500px" }}className="alert alert-danger mt-3 mb-0">{message}</div> : <div></div>
                            }
                        </form>
                </CardBody>
                </Card>
            </div>
            </div>
        </div>
    )}else{
        return (
            <Login/>
        )
    }
}
export default TaskDelete;