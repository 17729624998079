import React, {useEffect} from "react";
import TimesheetService from "../../repository/timesheetsRepository";
import {Card, CardBody} from "reactstrap";
import projectRepository from "../../repository/projectRepository";
import {useHistory} from "react-router-dom";
import timesheetsRepository from "../../repository/timesheetsRepository";
import Login from "../Login/Login";

const TaskAdd = (props) => {
    const history = useHistory();
    const [openAdd, setOpenAdd] = React.useState(false);
    const [formData, updateFormData] = React.useState({
        name: "",
        description: "",
        workPackageId:props.workPackageId,
        maxHours: 0.0,
        dateStart: "",
        dateEnd: "",

    });
    const [project,setProject] = React.useState({});

    if (project==={}){
    TimesheetService.getWorkPackage(props.workPackageId).then((workPackage)=>{
            setProject(workPackage.data.project);
        })}

   

    const reformatDate = (date) => {
        if (date) {
            const split = date.split(".");
            return split[2] + "/" + split[1] + "/" + split[0];
        }
    }

    const formatDate = (date) => {
        const split = date.split("-");
        return split[2] + "." + split[1] + "." + split[0];
    }

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleCancel = () => {
        props.handleCloseAdd();
    }



    const submitForm = (e) => {
        e.preventDefault();
        const name = formData.name;
        const description = formData.description;
        const workProjectId = formData.workPackageId;
        timesheetsRepository.getWorkPackage(workProjectId).then((snapshot)=>{
          
            const workPackage = snapshot.data;
           
            timesheetsRepository.addTask(name,description,workPackage,formatDate(formData.dateStart),formatDate(formData.dateEnd),formData.maxHours).then(() =>{
            projectRepository.getTasksByWorkPackage(workProjectId).then((snapshot)=>{
                if (snapshot.data.length > 0){
                    history.push({
                        pathname: '/work_packages/tasks/' + workProjectId,
                        state:{
                            tasks:snapshot.data,
                            workPackageId:workProjectId
                        }
                    });
                    window.location="/work_packages/tasks/"+workProjectId;
                }else{
                    history.push({
                        pathname: '/work_packages/tasks/' + workProjectId,
                        state:{
                            tasks:[],
                            workPackageId:workProjectId
                        }
                    })
                    window.location="/work_packages/tasks/"+workProjectId;
                }
            });});

        })


    }
    if(localStorage.getItem("email")!==null){
    return(
        <div className="container">

            <div className="row mt-5" >
                <Card style={{marginLeft:"25%"}}><CardBody>
                    <h3 className="formHeader">Add Work Task</h3>
                    <div className="col">

                        <form onSubmit={submitForm}>
                            <div className="form-group">

                                <input type="text"
                                       className="form-control inputForm"
                                       name="name" required placeholder="Name"  id="name"
                                       onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">

                                <textarea
                                       className="form-control inputForm"
                                       name="description" required placeholder="Description"  id="description"
                                       onChange={handleChange}
                                />
                            </div>
                            <label>Start date</label>
                            <div className="form-group">
                                <input type="date" className="form-control inputForm" required id="dateStart"
                                       name="dateStart" placeholder="Start Date" min={reformatDate(project.startDate)} max={reformatDate(project.endDate)}
                                       onChange={handleChange}/>


                            </div>
                            <label>End date</label>
                            <div className="form-group">
                                <input type="date" className="form-control inputForm" required id="dateEnd"
                                       name="dateEnd" placeholder="End Date" min={formData.dateStart} max={reformatDate(project.endDate)}
                                       onChange={handleChange}/>


                            </div>
                            <label>Planned hours</label>
                            <div className="form-group">
                                <input type="text" className="form-control" id="maxHours"
                                       name="maxHours" placeholder="Maximum Hours" defaultValue={0}
                                       onChange={handleChange}/>


                            </div>

                            <button type="button" id="submit" className="btn saveBtn" onClick={submitForm}>Save</button> <br/>
                            <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                        </form>
                    </div>
                </CardBody></Card>
            </div>
        </div>
    )}else{
        return (
            <Login/>
        )
    }
}
export default TaskAdd;