import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import TimesheetService from "../../repository/timesheetsRepository";
import Login from "../Login/Login";
import ProjectService from "../../repository/projectRepository";
import MemberRepository from "../../repository/membersRepository";
import FileSaver from "file-saver";

const Reports = (props) => {
  const history = useHistory();
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalElements, setTotalElements] = React.useState(0);
  const [projects, setProjects] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [reportHelper, setReportHelper] = React.useState({
    projectId: null,
    memberId: null,
    numberOfMonth: new Date().getMonth() + 1,
    numberOfYear: new Date().getFullYear(),
    numberOfMonthTo: new Date().getMonth() + 1,
    numberOfYearTo: new Date().getFullYear(),
    dateTo:null,
  });
  const [projectName, setProjectName]=React.useState()
  const [memberName,setMemberName]=React.useState()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // getAllTimeSheetsByMonthAndYear(newPage);
  };

  const handleCloseEdit = () => {
    setOpen(false);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (selected, action) => {
    if(action.name==="projectId"){
      setProjectName(selected.label)
    }
    else if(action.name==="memberId"){
      setMemberName(selected.labelFirstName)
    }

    setReportHelper({
      ...reportHelper,
      [action.name]: selected.value,
    });
  };
  const handleChangeDateTo=(e)=>{
    setReportHelper({
      ...reportHelper,
      dateTo:new Date(e.target.value)
    })

  }

  const getAllProjects = () => {
    ProjectService.fetchProjects().then((res) => {
      if (res.data.result === undefined) {
        setProjects([]);
      } else {
        setProjects(res.data.result);
      }
    });
  };
  const getAllMembers = () => {
    MemberRepository.fetchMembers().then((res) => {
      setMembers(res.data.result);
    });
  };
  const getAllForTimesheets = () => {
    MemberRepository.getAllForTimesheets().then((res) => {
      setUsers(res.data);
    });
  };
  const exportToExcelTimesheets = () => {
    const helper = {
      projectId: reportHelper.projectId,
      memberId: reportHelper.memberId,
      numberOfMonth: reportHelper.numberOfMonth,
      numberOfYear: reportHelper.numberOfYear,
      numberOfMonthTo: reportHelper.numberOfMonthTo,
      numberOfYearTo: reportHelper.numberOfYearTo,
      dateTo:reportHelper.dateTo,
    };
    TimesheetService.exportToExcelTimesheets(helper)
        .then((response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream; charset=UTF-8",
          });
          FileSaver.saveAs(blob, `${projectName}_${memberName}.xlsx`);

        })
        .catch((error) => {
          if (error.request) {
          }
        });
  };
  useEffect(() => {
    getAllForTimesheets();
    getAllProjects();
    getAllMembers();
  }, [rowsPerPage]);
  if (localStorage.getItem("email") !== null) {
    const optionProjects = projects.map((project) => {
      return { value: project.id, label: project.name };
    });
    const optionMembers = members.map((member) => {
      return {
        value: member.id,
        labelFirstName:member.user.firstName,
        label:
            member.user.firstName +
            " " +
            member.user.lastName +
            " " +
            "(" +
            member.user.email +
            ")",
      };
    });
    const optionUsers = users.map((user) => {
      return {
        value: user.memberId,
        label:
            user.firstName + " " + user.lastName + " " + "(" + user.email + ")",
      };
    });
    const optionsMonths = [
      { value: "1", label: `January` },
      { value: "2", label: `February` },
      { value: "3", label: `March` },
      { value: "4", label: `April` },
      { value: "5", label: `May` },
      { value: "6", label: `June` },
      { value: "7", label: `July` },
      { value: "8", label: `August` },
      { value: "9", label: `September` },
      { value: "10", label: `October` },
      { value: "11", label: `November` },
      { value: "12", label: `December` },
    ];
    var from = 2015;
    var to = 2028;
    var optionsYears = [];
    for (var y = from; y <= to; y++) {
      optionsYears.push({ value: y, label: y });
    }
    return (
        <div className="container">
          <div className="row pt-3">
            <Select
                name="projectId"
                placeholder={"Project"}
                className="col-6"
                isMulti={false}
                onChange={handleChange}
                options={optionProjects}
            ></Select>
            {localStorage.getItem("role") !== "ROLE_ADMIN" ? (
                <Select
                    name="memberId"
                    placeholder={"Choose member"}
                    className="col-6"
                    isMulti={false}
                    onChange={handleChange}
                    options={optionUsers}
                ></Select>
            ) : (
                <Select
                    name="memberId"
                    placeholder={"Member"}
                    className="col-6"
                    isMulti={false}
                    onChange={handleChange}
                    options={optionMembers}
                ></Select>
            )}
          </div>
          <div className="row pt-3">
            <Select
                name="numberOfMonth"
                placeholder={new Date().toLocaleString("default", {
                  month: "long",
                })}
                className="col-6"
                isMulti={false}
                onChange={handleChange}
                options={optionsMonths}
            ></Select>

            <Select
                name="numberOfYear"
                placeholder={new Date().getFullYear()}
                className="col-6"
                isMulti={false}
                onChange={handleChange}
                options={optionsYears}
            ></Select>
          </div>
          <div className="row pt-3">
            <Select
                name="numberOfMonthTo"
                placeholder={new Date().toLocaleString("default", {
                  month: "long",
                })}
                className="col-6"
                isMulti={false}
                onChange={handleChange}
                options={optionsMonths}
            ></Select>

            <Select
                name="numberOfYearTo"
                placeholder={new Date().getFullYear()}
                className="col-6"
                isMulti={false}
                onChange={handleChange}
                options={optionsYears}
            ></Select>
          </div>
          <br />
          <button
              className="btn btn-md btn-info"
              onClick={exportToExcelTimesheets}
          >
            Generate Reports
          </button>
        </div>
    );
  } else {
    return <Login />;
  }
};

export default Reports;