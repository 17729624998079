    import React from 'react';
    import {Card, CardBody} from "reactstrap";
    import Swal from "sweetalert2";
    import membersRepository from "../../repository/membersRepository";
    import Select from 'react-select';
    import Login from "../Login/Login";



    const MemberAdd = props => {


        const [error, setError] = React.useState(false);
        const [message, setErrorMessage] = React.useState("");

        const [formData, updateForDat] = React.useState({
            email: "",
            firstName: "",
            lastName: "",
            role: 1,
            username: ""
        })
        const handleChangeSelect = (value, action) => {
            updateForDat({
                ...formData,
                [action.name]: value
            })
        }
        const handleChange = (e) =>{
            updateForDat({
                ...formData,
                [e.target.name]: e.target.value.trim()
            })
        }

        const onFormSubmit = (e) => {
            e.preventDefault();
            const email = formData.email;
            const firstName = formData.firstName;
            const lastName = formData.lastName;
            const username = "username";
            const role = formData.role;
            membersRepository.getOneRole(role.value).then((snapshot)=>{
             
                membersRepository.addUser(email, firstName, lastName, username, snapshot.data).then(() => {
                        window.location = "/users";
                })
            }).catch(error => {
                if(error.message==="Request failed with status code 400") {
                    setError(true);
                   setErrorMessage("Please fill all required fields!");
                }
            });


        }

        let options = [];
        props.roles.map((role)=>{
            options.push({value:role.id,label:role.name})
        })

        const handleCancel = () => {
            props.handleCloseAdd();
        }
        if(localStorage.getItem("email")!==null){
        return (
            <div className="container">

                <div className="row mt-5" >
                    <Card style={{marginLeft:"25%", marginTop:'5%'}}><CardBody>
                        <h3 className="formHeader">Add User</h3>
                        <div className="col">

                            <form onSubmit={onFormSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control inputForm" id="email"
                                        name="email"  
                                        onChange={handleChange} required/>


                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstName">Firstname</label>
                                    <input type="text" className="form-control inputForm" id="firstName"
                                        name="firstName"
                                        onChange={handleChange}/>


                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Lastname</label>
                                    <input type="text" className="form-control inputForm" id="lastName"
                                        name="lastName"
                                        onChange={handleChange}/>


                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control inputForm" id="username"
                                        name="username" required
                                        onChange={handleChange}/>


                                </div> */}
                                <div className="form-group">
                                    <label>Role</label>
                                
                                    <Select name="role" onChange={handleChangeSelect} options={options} required >

                                    </Select>


                                </div>
                                <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>

                            </form>
                            <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                            {error === true ?
                            <div className="alert alert-danger mt-3 mb-0">{message}</div> : <div></div>
                        }
                        </div>
                    </CardBody></Card>
                </div>
            </div>
        );}else{
            return (
                <Login/>
            )
        }
    };



    export default MemberAdd;

