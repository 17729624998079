import {Card, CardBody} from "reactstrap";
import React from "react";
import TimesheetService from "../../repository/timesheetsRepository";
import Select from "react-select";
import {useHistory} from "react-router-dom";
import timesheetsRepository from "../../repository/timesheetsRepository";
import Login from "../Login/Login";


const WorkPackageAdd = (props) => {

    const history = useHistory();
    const [formData, updateFormData] = React.useState({
        name: "",
        projectId:props.projectId,
        projects:null
    });


    const handleChangeSelect = (value, action) => {
        updateFormData({
            ...formData,
            [action.name]: value
        })
    }
    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }


    const submitForm = (e) => {
        e.preventDefault();

        const name = formData.name;
        let projectId = formData.projectId;
       
        let flag = false;
        if (formData.projects !== null){
            projectId = formData.projects;
            flag=true;
        }
        if(flag===true){
        TimesheetService.getProject(projectId.value).then((snapshot)=>{
            const project = snapshot.data;
            TimesheetService.addWorkPackage(name,project).then(()=>{
                if(props.comesFrom==="root"){
                    window.location="/work_packages";
                }else{

                    timesheetsRepository.getAllWorkingPackagesByProject(projectId).then((snapshot) => {
                        let projectsWPs = [];
                        if (snapshot.data.length > 0) {
                            projectsWPs=snapshot.data;

                        }
                        history.push({
                            pathname: '/project/work_packages/'+projectId,
                            state:{
                                workPackages: projectsWPs,
                                projectId: projectId
                            }
                        });
                        history.go(0);

                    });


                }
            });

        })}else{
            TimesheetService.getProject(projectId).then((snapshot)=>{
                const project = snapshot.data;
              
                TimesheetService.addWorkPackage(name,project).then(()=>{
                    if(props.comesFrom==="root"){
                        window.location="/work_packages";
                    }else{
                        timesheetsRepository.getAllWorkingPackagesByProject(projectId).then((snapshot) => {
                            let projectsWPs = [];
                            if (snapshot.data.length > 0) {
                                projectsWPs=snapshot.data;

                            }
                            history.push({
                                pathname: '/project/work_packages/'+projectId,
                                state:{
                                    workPackages: projectsWPs,
                                    projectId: projectId
                                }
                            });
                            history.go(0);

                        });
                    }
                });

            })
        }


    }
    let options = [];
    if(props.projects){
        props.projects.map((project)=>{
            options.push({value:project.id, label: project.name})
        })

    }
    const handleCancel = () => {
        props.handleCloseAdd();
    }

    if(localStorage.getItem("email")!==null){
    return(
        <div className="container">

            <div className="row mt-5" >
                <Card style={{marginLeft:"20%",marginTop:"10%"}}><CardBody>
                    <h3 className="formHeader">Add Work Package</h3>
                    <div className="col">

                        <form onSubmit={submitForm}>
                            <div className="form-group">

                                <input type="text"
                                       className="form-control inputForm"
                                       name="name" required placeholder="Name" id="name"
                                       onChange={handleChange}
                                />
                            </div>

                            {props.projects && <div className="form-group">
                                <Select name="projects" required onChange={handleChangeSelect} options={options}>

                                </Select>
                            </div>}

                            <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>
                            <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>

                            
                        </form>
                    </div>
                </CardBody></Card>
            </div>
        </div>
    )}else{
        return (
            <Login/>
        )
    }
}
export default WorkPackageAdd;