import React from "react";
import TimesheetService from "../repository/timesheetsRepository";
import memberTypeRepository from "../repository/memberTypeRepository";
import {Card, CardBody} from "reactstrap";
import Login from "../components/Login/Login";

const MemberTypeAdd = props => {

    const [formData, updateForDat] = React.useState({
        name: "",

    })

    const handleChange = (e) =>{
        updateForDat({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const submitForm = (e) => {
        e.preventDefault();
        const name = formData.name;
       
        memberTypeRepository.addMemberType(name);
        window.location="/memberTypes";
    }

    const handleCancel = () => {
        props.handleCloseAdd();
    }


    if(localStorage.getItem("email")!==null){
        return (
            <div className="container">

                <div className="row mt-5" >
                    <Card style={{marginLeft:"20%",marginTop:"10%"}}><CardBody>
                        <h3 className="formHeader">Add MemberType</h3>
                        <div className="col">

                            <form onSubmit={submitForm}>
                                <div className="form-group">

                                    <label htmlFor="name">Name</label>
                                    <input type="text"
                                           className="form-control inputForm"
                                           name="name" required id="name"
                                           onChange={handleChange}

                                    />
                                </div>
                                <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>

                            </form>
                            <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                        </div>
                    </CardBody></Card>
                </div>
            </div>

        )}
    else{
        return (<Login/>)
    }

}
export default MemberTypeAdd;