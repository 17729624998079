import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import React, {useState} from "react";

const ItemHoursAll = (props) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [filteredItems, setItems] = useState([]);


    return (
        <div className="container">

            {/*{props.location.state.error && <div className="alert alert-danger" role="alert">*/}
            {/*    {props.location.state.error}*/}
            {/*</div>}*/}

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard">
                        <CardHeader title={"Task Details"}></CardHeader>
                        <CardBody>

                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Task Description</TableCell>
                                                    <TableCell data-priority="3">Members</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody id="taskMemberBodyTable">

                                                {filteredItems.length > 0 ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.task.description}</TableCell>
                                                            <TableCell className="innerCell">{uni.member.user.firstName + " " + uni.member.user.lastName}</TableCell>
                                                        </TableRow>
                                                    )
                                                }) : props.location.state.task.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {
                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell className="innerCell">{uni.task.description}</TableCell>
                                                            <TableCell className="innerCell">{uni.member.user.firstName + " " + uni.member.user.lastName}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={filteredItems.length>0?filteredItems.length:props.location.state.task.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />

                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>
        </div>
    )


}
export default ItemHoursAll;