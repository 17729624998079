import React, {useState} from 'react';
import {Card, CardBody} from "reactstrap";
import projectRepository from "../../repository/projectRepository";
import Select from "react-select";
import Login from "../Login/Login";

const ProjectAdd = props => {

    const [formData, updateForData] = React.useState({
        name: "",
        projectNumber: "",
        estimatedBudget: 0,
        startDate: "",
        endDate: "",
        university: "",
        approved: "",

    })
    const [members, setMembers] = React.useState("");

    const handleChangeSelect = (value, action) => {
        updateForData({
            ...formData,
            [action.name]: value
        })
    }

    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const formatDate = (date) => {
        const split = date.split("-");
        return split[2] + "." + split[1] + "." + split[0];
    }


    const onFormSubmit = (e) => {
        e.preventDefault();
        const name = formData.name;
        const projectNumber = formData.projectNumber;
        const estimatedBudget = formData.estimatedBudget;
        const startDate = formatDate(formData.startDate);
        const endDate = formatDate(formData.endDate);
        const university = formData.university;
        const approved = formData.approved;

      
        projectRepository.getUniversity(university.value).then((snapshot) => {
            let isApproved = false;
            if (approved === "on") {
                isApproved = true;
            }
            projectRepository.addProject(name, projectNumber, estimatedBudget, startDate, endDate, snapshot.data, isApproved)
            window.location = "/projects"
        })


    }
    let options = [];
    props.universities.map((uni) => {
        options.push({value: uni.id, label: uni.name})
    })

    const handleCancel = () => {
        props.handleCloseAdd();
    }

    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">

            <div className="row mt-5">
                <Card style={{marginLeft: "20%", marginTop: "-8%"}}>
                    <CardBody>
                        <h3 className="formHeader">Add Project</h3>
                        <div className="col">

                            <form onSubmit={onFormSubmit}>
                                <div className="form-group">

                                    <label htmlFor="name">Name</label>
                                    <input type="text"
                                           className="form-control inputForm"
                                           name="name" required id="name"
                                           onChange={handleChange}


                                    />
                                </div>
                                <div className="form-group">

                                    <label htmlFor="projectNumber">Project number</label>
                                    <input type="text" className="form-control inputForm" id="projectNumber"
                                           name="projectNumber"
                                           onChange={handleChange}/>


                                </div>
                                <div className="form-group">
                                    <label>Institution</label>
                                    <Select name="university" required onChange={handleChangeSelect} options={options}>

                                    </Select>


                                </div>
                                <div className="form-group">
                                    <label htmlFor="estimatedBudget">Estimated budget</label>
                                    <input required type="text" className="form-control inputForm" id="estimatedBudget"
                                           name="estimatedBudget"
                                           onChange={handleChange}/>


                                </div>
                                <div className="form-group">

                                    <label htmlFor="startDate">Start date</label>
                                    <input required type="date" className="form-control inputForm" id="startDate"
                                           name="startDate"
                                           onChange={handleChange}/>


                                </div>
                                <div className="form-group">
                                    <label htmlFor="endDate">End date</label>
                                    <input required type="date" className="form-control inputForm" id="endDate"
                                           name="endDate"  min={formData.startDate}
                                           onChange={handleChange}/>


                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Approved</label>
                                    <input required type="checkbox" className="form-control inputForm" id="approved"
                                           name="approved"
                                           onChange={handleChange}/>


                                </div>


                                <button type="submit" id="submit" className="btn saveBtn">Save</button>
                                <br/>

                            </form>
                            <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                        </div>
                    </CardBody></Card>
            </div>
        </div>
    );}
    else {
        return (
            <Login/>
        )
    }
};


export default ProjectAdd;