import React, { Component } from "react";
import {Button} from "reactstrap";
import './LocalLogin.css';
import AuthenticationService from "../../custom-axios/AuthenticationService";
    import {Alert, Spinner} from "reactstrap";
const AUTH_TOKEN = "auth_token";
const EMAIL = "email";
const ROLES = "role";

export default class LocalLogin extends Component {
    state = {
        data: {
            email: "",
            password: "",
        },
        errorMessage: "",
        errorMessageVisible: false,
    };

    handleChange = (e) =>
        this.setState({
            data: {...this.state.data, [e.target.name]: e.target.value},
        });

    handleSubmit = (e) => {
        e.preventDefault();
        const {data} = this.state;
        let data2 = data.email + ":" + data.password;
        let k = data2.split(":");
        const request = Buffer.from(data2).toString("base64");
        AuthenticationService.loginUser(request)
            .then((res) => {
                localStorage.setItem(AUTH_TOKEN, res.data);
                localStorage.setItem(EMAIL, this.parseJwt(res.data).sub)
                localStorage.setItem(ROLES, this.parseJwt(res.data).authority)
                
                AuthenticationService.userRole().then((res) => {
                    console.log(res.data)
                    localStorage.setItem("memberId", res.data.memberId)
                    localStorage.setItem('id', res.data.id);
                    localStorage.setItem('nameSurname', res.data.firstName + " " + res.data.lastName);
                    //localStorage.setItem("ROLES", res.data.roles);
                    //localStorage.setItem("loggedUserId", res.data.id);
                    window.location.href = "/projects";
                }).finally();

            })
            .catch((err) => {
                if (err.message === "Request failed with status code 403") {
                    this.setState({
                        errorMessageVisible: true,
                        errorMessage: "Вашата е-маил адреса или лозинка се неточни",
                    });
                }
            });
        //alert("Успешна најава!");

    };

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    };

    render() {
        const {data} = this.state;
        let data2 = data.email + ":" + data.password;
        let k = data2.split(":");
        return (
            
<div class="container-fluid" style={{marginTop: '9.375rem'}}>
    	<div class="row" style={{ textAlign: '-webkit-center' }}>
            <div class="col-12" style={{ margin: 'auto' }}>
			<div class="col-md-4 col-md-offset-3" >
				<div class="panel panel-login" >
					
					<div class="panel-body" style={{border:'1px solid #cecece', padding:'3rem'}}>
						<div class="row">
							<div class="col-lg-12">
								<form id="login-form" style={{display:'block'}} onSubmit={this.handleSubmit}>
									<div class="form-group">
										<input class="form-control" type="email" id="email" name="email" value={k[0]} onChange={this.handleChange} placeholder="Внесете е-маил адреса" required/>
									</div>
									<div class="form-group">
										<input class="form-control" type="password" id="password" name="password" value={k[1]} onChange={this.handleChange} placeholder="Внесете лозинка" required/>
									</div>
									<div class="form-group">
										<div class="row">
											<div class="container-fluid" style={{textAlign:'center'}}>
                                            <br/>
												<input type="submit" class="form-control btn infoBtn" value="Најавете се"/>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="row">
											<div class="col-lg-12">
												<div class="text-right">
													<a class="idp" href="/oidc/login">Најавете се преку idp</a>
												</div>
											</div>
                                            <br/><br/><br/>
                                            {this.state.errorMessageVisible && (
                                            <div class="alert alert-danger" style={{width:'-webkit-fill-available'}} role="alert">
                                             {this.state.errorMessage}
                                            </div>
                                             )}
										</div>
									</div>
								</form>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
      </div>
	</div>

        );
    }
}
