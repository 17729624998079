import {Card, CardBody} from "reactstrap";
import React, {useState} from "react";
import holidaysRepository from "../../repository/holidaysRepository";
import format from 'date-fns/format';
import dateFormat from 'dateformat';
import Login from "../Login/Login";
import moment from "moment";
//import DatePicker from 'react-date-picker';




const HolidayEdit = props => {
    const [formData, updateFormData] = React.useState({
        id:props.id,
        name: props.name,
        date: props.date,
    });
    const handleChange = (e) => {
        //const [value, onChange] = useState(new Date());

        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
            // [e.target.date]: e.target.value.trim()
        })
      
    }
    const reformatDate = (date) => {
        if (date) {
            const split = date.split(".");
         
            let a = new Date(split[2] + "/" + split[1] + "/" + split[0])
            a.setHours(6)
            return a
        }
    }

    const formatDate = (date) => {
        const split = date.split("-");
        return split[2] + "." + split[1] + "." + split[0];

    }



    const submitForm = (e) => {
        e.preventDefault();
        const id = formData.id;
        const name = formData.name;
        const dateTest = formData.date;
        // const date =  formData.date || formatDate(formData.date);
        let date = null;
        if(formatDate(formData.date).includes("undefined")){
            date = formData.date
        }
        else{
            date=formatDate(formData.date)
        }
        console.log(date)
        holidaysRepository.editHoliday(id,name,date).then(() => {
             window.location = "/holidays";
        })
    }

    const handleCancel = () => {
        props.handleCloseEdit();
    }
    if (localStorage.getItem("email")!==null){
        return (
            <div className="container">

                <div className="row mt-5" >
                    <div className="col mainCol">
                        <Card style={{marginTop:"10%"}}>
                            <CardBody>
                                <h3 className="formHeader">Edit Holiday</h3>
                                <form onSubmit={submitForm}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text"
                                               className="form-control inputForm"
                                               name="name" required  id="name"
                                               onChange={handleChange}
                                               defaultValue={props.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="date">Date</label>
                                        <input required type="date"
                                            //    date-format="DD/MM/YYYY"
                                               className="form-control inputForm" id="date"
                                               name="date"
                                               onChange={handleChange}
                                            //    defaultValue={format(props.date, "YYYY-MM-DD")}/>
                                            // defaultValue={dateFormat(props.date,'isoDate')}
                                            // defaultValue={dateFormat(props.date,"mm.dd.yyyy")}
                                                defaultValue={reformatDate(props.date).toISOString().substring(0, 10)}
                                            />

                                        {/*<DatePicker date-format="DD/MM/YYYY"  className="form-control inputForm" id="date"
                                                name="date"
                                                defaultValue={props.date}
                                                onChange={handleChange}/>*/}

                                    </div>
                                    <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>
                                </form>

                                <button style={{marginTop:10}} type="button" className="btn saveBtn" onClick={handleCancel}>Cancel</button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

        )}else{
        return (
            <Login/>
        )
    }

}

export default HolidayEdit;