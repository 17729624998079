import React, { useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import projectRepository from "../../repository/projectRepository";
import membersRepository from "../../repository/membersRepository";

const ProjectListMembersDelete = (props) => {
  const [formData] = React.useState({
    id: props.projectId,
  });
 

  const handleCancel = () => {
    props.handleCloseDelete();
  };

  const submitForm = (e) => {
    e.preventDefault();
    let newListMembers = [];
    newListMembers.push(props.id)
    const projectMembers = {
      projectId: props.projectId,
      membersIds: newListMembers,
    };
    projectRepository.deleteMemberFromProject(projectMembers).then((res) => {
      console.log(res.data);
      window.location="/projects";
    });

  };
  useEffect(() => {
  }, []);
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col mainCol">
          <Card style={{ marginTop: "10%" }}>
            <CardBody>
              <h3 className="formHeader">Delete Member</h3>
              <form onSubmit={submitForm}>
                <div className="form-group">
                  Are you sure you want to delete the member?
                </div>
                <button type="submit" id="submit" className="btn dangerBtn">
                  Delete
                </button>{" "}
                <br />
                <button
                  style={{ marginTop: 10 }}
                  type="button"
                  className="btn dangerCancelBtn"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProjectListMembersDelete;
