import React from 'react';
import {Card, CardBody} from "reactstrap";
import projectRepository from "../../repository/projectRepository";
import Login from "../Login/Login";

const ProjectEdit = props => {


    const [formData, updateForData] = React.useState({
        id: props.projectId,
        name: props.name,
        projectNumber: props.projectNumber,
        estimatedBudget: props.estimatedBudget,
        startDate: props.startDate,
        endDate: props.endDate,
        university: props.universities,
        approved: props.approved
    })

    const [clicked,updateClicked] = React.useState(props.approved);



    const handleChange = (e) => {
        updateForData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleClick = (e) => {
      
        updateClicked(!clicked);
    }

    const formatDate = (date) => {
        const split = date.split("-");
        return split[2] + "." + split[1] + "." + split[0];
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const id = formData.id;
        const name = formData.name;
        const projectNumber = formData.projectNumber;
        const estimatedBudget = formData.estimatedBudget;
        const startDate = formatDate(formData.startDate);
        const endDate = formatDate(formData.endDate);
        const university = formData.university[0];
        projectRepository.editProject(id, name, projectNumber, estimatedBudget, startDate, endDate, university, clicked)
        window.location = "/projects"



    }

    if(localStorage.getItem("email")!==null){

    return (
        <div className="container">
            <div className="row mt-5">
                <Card style={{marginTop: "10%"}}><CardBody>
                    <h3 className="formHeader">Edit Project</h3>
                    <div className="col">

                        <form onSubmit={onFormSubmit}>
                            <div className="form-group">

                                <input type="text"
                                       className="form-control inputForm"
                                       name="name" required id="name"
                                       onChange={handleChange}
                                       defaultValue={props.name}

                                />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control inputForm" id="projectNumber"
                                       name="projectNumber" required placeholder="Project Number"
                                       onChange={handleChange}
                                       defaultValue={props.projectNumber}
                                />


                            </div>
                            <div className="form-group">
                                <label>Faculty</label>
                                <select name="university" required className="form-control" onChange={handleChange}>
                                    {props.universities.map((term) =>
                                        <option value={term.id}>{term.name}</option>
                                    )}
                                </select>


                            </div>
                            <div className="form-group">
                                <input type="text" required className="form-control inputForm" id="projectManager"
                                       name="projectManager" placeholder="Project Manager"
                                       onChange={handleChange}

                                />


                            </div>
                            <div className="form-group">
                                <input type="text" required className="form-control inputForm" id="estimatedBudget"
                                       name="estimatedBudget" placeholder="Estimated Budget"
                                       onChange={handleChange}
                                       defaultValue={props.estimatedBudget}
                                />


                            </div>
                            <div className="form-group">
                                <input type="date" required className="form-control inputForm" id="startDate"
                                       name="startDate" placeholder="Start Date"
                                       onChange={handleChange}
                                       defaultValue={props.startDate}
                                />


                            </div>
                            <div className="form-group">
                                <input type="date" required className="form-control inputForm" id="endDate"
                                       name="endDate" placeholder="End Date"
                                       onChange={handleChange}
                                       defaultValue={props.endDate}
                                />


                            </div>
                            {clicked === true ? <div className="form-group">
                                <input type="checkbox" className="form-control inputForm" id="approved"
                                       name="approved" placeholder="Approved"
                                       onClick={handleClick}
                                       checked
                                />


                            </div> :<div className="form-group"> <input type="checkbox" className="form-control inputForm" id="approved"
                                            name="approved" placeholder="Approved"
                                            onClick={handleClick}

                            />
                            </div>


                            }

                            <button type="submit" id="submit" className="btn saveBtn">Save</button>
                            <br/>

                        </form>
                    </div>
                </CardBody></Card>
            </div>
        </div>
    );}else{
        return (<Login/>)
    }
};


export default ProjectEdit;