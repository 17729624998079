    import './App.css';
    import Sidebar from '../Sidebar/Sidebar';
    import {Switch, BrowserRouter as Router, Route} from "react-router-dom"
    import UniversityAdd from "../Universities/universityAdd";
    import React, {Component} from "react";
    import TimesheetService from "../../repository/timesheetsRepository";
    import Nav from "../Navbar/Nav";
    import UniversitiesAll from "../Universities/universitiesAll";
    import UniversityDetails from "../Universities/universityDetails";
    import UniversityEdit from "../Universities/UniversityEdit";
    import UniversityDelete from "../Universities/UniversityDelete";
    import ProjectsAll from "../Projects/ProjectsAll";
    import ProjectAdd from "../Projects/ProjectAdd";
    import ProjectDelete from "../Projects/ProjectDelete";
    import WorkPackagesAll from "../WorkPackages/WorkPackagesAll";
    import WorkPackageAdd from "../WorkPackages/WorkPackageAdd";
    import WorkPackagesFromEveryProject from "../WorkPackages/WorkPackagesFromEveryProject";
    import TasksByWorkPackage from "../Tasks/TasksByWorkPackage";
    import ItemsAll from "../Items/ItemsAll";
    import ItemAdd from "../Items/ItemAdd";
    import ItemHoursAll from "../ItemHours/ItemHoursAll";
    import MemberAdd from "../Members/MemberAdd";
    import membersRepository from "../../repository/membersRepository";
    import MemberAll from "../Members/MemberAll";
    import ProjectMembersAdd from "../Projects/ProjectMembersAdd";
    import ProjectEdit from "../Projects/ProjectEdit";
    import projectRepository from "../../repository/projectRepository";
    import MemberDelete from "../Members/MemberDelete";
    import UsersAll from "../Users/UsersAll";
    import UserAdd from "../Users/UserAdd";
    import timesheetsRepository from "../../repository/timesheetsRepository";

    import TimesheetsAll from "../TImesheets/TimesheetsAll";
    import ItemHourRepository from "../../repository/itemHourRepository";
    import HolidayService from "../../repository/holidaysRepository";
    import HolidaysAll from "../Holidays/HolidaysAll";
    import HolidaysAdd from "../Holidays/HolidaysAdd";
    import HolidayEdit from "../Holidays/HolidayEdit";
    import LocalLogin from "../Login/LocalLogin";
    import UserEdit from "../Users/UserEdit";
    import memberTypeRepository from "../../repository/memberTypeRepository";
    import MemberTypeAll from "../../memberTypes/MemberTypeAll";
    import MemberTypeAdd from "../../memberTypes/MemberTypeAdd";
    import MemberTypeEdit from "../../memberTypes/MemberTypeEdit";
import MyTimeSheets from '../MyTimeSheets/MyTimeSheets';
import Reports from '../reports/Reports';


    class App extends Component {

        constructor(props) {
            super(props);
            this.state = {
                universities: [],
                projects: [],
                filteredProjects: [],
                workPackages: [],
                members: [],
                memberType: [],
                users: [],
                roles: [],
                timesheetsForMember: [],
                holidays: [],
                isAuthenticated: true,
                memberTypes: []

            }
        }

        componentDidMount() {
            this.loadLoggedInfo();
            // this.getLoggedInfo()
            this.loadUniversities();
            this.loadProjects();
            this.loadMembers();
            this.loadMemberType();
            this.loadUser();
            // this.loadWorkPackages();
            // this.loadTimesheetsForMember();
            this.loadRoles();
            this.loadHolidays();
            // this.loadAuth();
            this.loadMemberTypes();


        }



        loadLoggedInfo = async () => {

            let currentUrl = window.location.href;
            if (currentUrl.includes("getLoggedDetails")) {
                const currentId = currentUrl.split("/").pop();
                await membersRepository.getLoggedInfo(currentId).then((snapshot) => {
                    console.log("logged", snapshot)
                    localStorage.setItem('role', snapshot.data.role);
                    localStorage.setItem('email', snapshot.data.email);
                    localStorage.setItem('uniqueId', snapshot.data.uniqueID);
                    localStorage.setItem('username', snapshot.data.username);

                });

            window.location.href = "/sidebar"


            }
        }
         getLoggedInfo=()=>{
            membersRepository.getLoggedUserInfo().then(res=>{
                localStorage.setItem('role', res.data.role);
                localStorage.setItem('email', res.data.email);
                localStorage.setItem('id', res.data.id);
                localStorage.setItem('nameSurname', res.data.firstName + " " + res.data.lastame);
            })
          }

        loadProjects = () => {

            projectRepository.fetchProjects()
                .then(async (data) => {
                    let finalProjectData = [];
                    let atLeastOnce = false;
                    let projectRole = "";
                    if (data.data.length !== 0) {
                        const projectData = data.data.result;
                        console.log(projectData)
                        // for (var i = 0; i < projectData.length; i++) {
                        //     atLeastOnce=false;
                        //     let managers = "";
                        //     let memberEmails = [];
                        //     await membersRepository.getAllMemberMemberTypeProjectsByProject(projectData[i].id).then((snapshot) => {
                        //         if (snapshot.data.length > 0) {
                        //             const snapData = snapshot.data;
                        //             if (snapData.length > 0) {
                        //                 for (var j = 0; j < snapData.length; j++) {
                        //                     if (snapData[j].member.user.email.toString() === localStorage.getItem('email')) {
                        //                         atLeastOnce = true;
                        //                         projectRole = snapData[j].memberType.name;
                        //                     }

                        //                     if (snapData.length === 1) {
                        //                         if (snapData[j].memberType.name === "Coordinator") {
                        //                             managers = managers + snapData[j].member.user.firstName + " " + snapData[j].member.user.lastName;
                        //                         }
                        //                     } else {
                        //                         if (j < snapData.length - 1) {
                        //                             if (snapData[j].memberType.name === "Coordinator") {
                        //                                 managers = managers + snapData[j].member.user.firstName + " " + snapData[j].member.user.lastName + ",";
                        //                             }
                        //                         } else {
                        //                             if (snapData[j].memberType.name === "Coordinator") {
                        //                                 managers = managers + snapData[j].member.user.firstName + " " + snapData[j].member.user.lastName;
                        //                             }
                        //                         }
                        //                     }
                        //                 }
                        //             }

                        //         }
                        //     })
                        //     projectData[i].projectManager = managers;
                        //     if (atLeastOnce === true) {
                        //         projectData[i].roleOfLogged = projectRole;
                        //         finalProjectData.push(projectData[i]);

                        //     }
                        // }
                       
                        this.setState({
                            projects: projectData

                        });
                      

                    }
                }).catch(() => {
               
                this.setState({isAuthenticated: false})
            });

        }
        loadMember = (id) => {
            membersRepository.getOneMember(id).then((snapshot) => {
                this.setState({loggedMember: snapshot.data});
            }).catch(() => {
               
                this.setState({isAuthenticated: false})
            });
        }
        loadMembers = () => {
            membersRepository.fetchMembers()
                .then((data) => {
                  
                    this.setState({
                        members: data.data.result
                    });

                }).catch(() => {
               
                this.setState({isAuthenticated: false})
            });
            ;
        }
        loadMemberType = () => {
            membersRepository.fetchMemberType()
                .then((data) => {
                    this.setState({
                        memberType: data.data.result
                    });
                }).catch(() => {
              
                this.setState({isAuthenticated: false})
            });
            ;
        }
        loadMemberTypes = () => {
            memberTypeRepository.fetchMemberTypes()
                .then((data) => {
                    this.setState({
                        memberTypes: data.data.result
                    });
                }).catch(() => {
                
                this.setState({isAuthenticated: false})
            });
            ;
        }
        loadUser = () => {
            membersRepository.fetchUsers()
                .then((data) => {
                    this.setState({
                        users: data.data
                    })
                }).catch(() => {
               
                this.setState({isAuthenticated: false})
            });
            ;
        }

        loadHolidays = () => {
            HolidayService.fetchHolidays()
                .then((data) => {
                    this.setState({
                        holidays: data.data
                    })
                }).catch(() => {
              
                this.setState({isAuthenticated: false})
            });
        }

        loadUniversities = () => {
            TimesheetService.fetchUniversities().then((snapshot) => {
                if (snapshot.data.length !== 0) {
                    this.setState({
                        universities: snapshot.data.result
                    })
                }
            }).catch(() => {
              
                this.setState({isAuthenticated: false})
            });
            ;
        }

        loadWorkPackages = () => {
            TimesheetService.getAllWorkPackages().then((snapshot) => {
                if (snapshot.data.length !== 0) {
                    this.setState({
                        workPackages: snapshot.data
                    })
                }

            }).catch(() => {
              
                this.setState({isAuthenticated: false})
            });
            ;
        }

        loadRoles = () => {
            membersRepository.fetchRole()
                .then((data) => {
                 
                    this.setState({
                        roles: data.data
                    })
                }).catch(() => {
               
                this.setState({isAuthenticated: false})
            });
        }

        loadAuth = () => {
            projectRepository.getUserAuth().then((snapshot) => {
               
            })
        }

        loadTimesheetsForMember = (id) => {
            timesheetsRepository.getTimesheetsForMember(id).then(async (snapshot) => {
                let data = snapshot.data;
                if (data.length !== 0) {
                    let itemHours = [];
                    for (var i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        const member = data[i].member.id;

                        for (var j = 0; j < data[i].tasks.length; j++) {
                            let hours = null
                            let sumHours = 0;
                            await projectRepository.getItemHoursByMemberTaskTimeSheet(member, data[i].tasks[j].id, id).then((resolve) => {
                                hours = resolve.data;
                            });
                            await ItemHourRepository.getTimeSheetTaskSum(id, data[i].tasks[j].id).then((resolve) => {
                                sumHours += resolve.data;
                            });
                            data[i].tasks[j]["itemHours"] = hours;
                            data[i].tasks[j]["totalHours"] = sumHours;


                        }
                    }
                    this.setState({
                        timesheetsForMember: data
                    })

                }
            }).catch(() => {
              
                this.setState({isAuthenticated: false})
            });
            ;
        }


        render() {
            return (
                <Router>
                    <Nav/>
                    <Sidebar/>
                    <main>
                        
                            <Switch>
                                <Route exact path="/universities/add" component={UniversityAdd}/>
                                <Route exact path="/universities"
                                    render={props => <UniversitiesAll universities={this.state.universities}/>}/>
                                <Route exact path="/universities/projects/:id?" component={UniversityDetails}/>
                                <Route exact path="/universities/edit/:id?" component={UniversityEdit}/>
                                <Route exact path="/universities/delete/:id?" component={UniversityDelete}/>
                                <Route path={"/projects"} exact render={() =>
                                    <ProjectsAll onProjectAdd={this.addProject}
                                                universities={this.state.universities}
                                                projects={this.state.projects}
                                                users={this.state.users}
                                                members={this.state.members}
                                                memberTypes={this.state.memberTypes}
                                    />}/>
                                <Route path={"/projects/add"} exact render={() =>
                                    <ProjectAdd universities={this.state.universities}
                                                onAddProject={this.addProject}/>}/>
                                <Route exact path="/projects/edit/:id?" component={ProjectEdit}/>
                                <Route exact path="/projects/delete/:id?" component={ProjectDelete}/>
                                <Route exact path="/work_packages/project/:projectId?" component={WorkPackagesAll}/>
                                <Route exact path="/work_packages/add/" component={WorkPackageAdd}/>
                                <Route path="/work_packages" exact render={() =>
                                    <WorkPackagesFromEveryProject workPackages={this.state.workPackages}/>}/>
                                <Route exact path="/work_packages/tasks/:workPackageId?" component={TasksByWorkPackage}/>
                                <Route exact path="/itemHours/task/:itemId?" component={ItemHoursAll}/>
                                <Route exact path="/items/task/:taskId?" component={ItemsAll}/>
                                <Route exact path="/items/add" component={ItemAdd}/>
                                <Route exact path="/itemHours/item/:itemId?" component={ItemHoursAll}/>
                                <Route exact path="/items/task/:taskId?" component={ItemsAll}/>
                                <Route exact path="/items/add" component={ItemAdd}/>
                                <Route exact path="/itemHours/item/:itemId?" component={ItemHoursAll}/>
                                <Route path={"/members"} exact render={() =>
                                    <MemberAll members={this.state.members}
                                            onMembersAdd={this.addMember}
                                            users={this.state.users}
                                            projects={this.state.projects}
                                            memberType={this.state.memberType}/>}/>
                                <Route path={"/members/add"} exact render={() =>
                                    <MemberAdd onAddMembers={this.addMember}
                                            memberType={this.state.memberType}
                                            projects={this.state.projects}
                                            users={this.state.users}/>}/>
                                <Route exact path="/memberType/delete/:id?" component={MemberDelete}/>
                                <Route path={"/project/assignMembers/:projectId?"} exact
                                    render={() => <ProjectMembersAdd projectId={122} memberType={this.state.memberType}
                                                                        users={this.state.users}/>}/>

                                <Route path={"/project/assignMembers/:projectId?"} exact render={() =>
                                    <ProjectMembersAdd projectId={122} memberType={this.state.memberType}
                                                    users={this.state.users}/>}/>
                                {/* <Route path={"/timesheets"} exact render={() =>
                                    <TimesheetsAll projects={this.state.projects} members={this.state.members}
                                                timesheets={this.state.timesheetsForMember}/>}/> */}
                                <Route path={"/users"} exact render={() =>
                                    <UsersAll users={this.state.users}
                                            roles={this.state.roles}
                                            onAddUsers={this.addUser}/>}/>
                                <Route path={"/users/add"} exact render={() =>
                                    <UserAdd onAddUsers={this.addUser}
                                            users={this.state.users}
                                            roles={this.state.roles}/>}/>
                                <Route exact path="/user/edit/:id?" component={UserEdit}/>
                                <Route path={"/holidays"} exact render={() =>
                                    <HolidaysAll holidays={this.state.holidays}
                                                onAddHolidays={this.addHoliday}
                                                editHoliday={this.editHoliday}/>}/>
                                <Route path={"/holidays/add"} exact render={() =>
                                    <HolidaysAdd onAddHolidays={this.addHoliday}
                                                holidays={this.state.holidays}/>}/>
                                <Route exact path={"/holidays/edit/:id?"} component={HolidayEdit}/>
                                <Route exact path={"/project/work_packages/:id?"} component={WorkPackagesAll}/>
                               
                                <Route path={"/oidc/login"}/>
                                <Route path={"/users/getLoggedDetails/:id?"}/>
                                <Route path={"/memberTypes"} exact render={() =>
                                    <MemberTypeAll memberTypes={this.state.memberTypes}
                                                 onAddMemberTypes={this.addMemberType}
                                                 editMemberType={this.editMemberType}/>}/>
                                <Route path={"/memberTypes/add"} exact render={() =>
                                    <MemberTypeAdd onAddMemberTypes={this.addMemberType}
                                                 memberTypes={this.state.memberTypes}/>}/>
                                <Route exact path={"/memberTypes/edit/:id?"} component={MemberTypeEdit}/>
                                <Route exact path={"/my-timesheets"} component={MyTimeSheets}/>
                                <Route exact path={"/reports"} component={Reports}/>
                                <Route path={"/"} component={LocalLogin}/>

                            </Switch>
                        
                    </main>
                </Router>


            );
        }
    }

    export default App;
