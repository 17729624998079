import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
//import react pro sidebar components
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu} from "react-pro-sidebar";

//import icons from react icons
import {FiArrowLeftCircle, FiArrowRightCircle, FiLogOut} from "react-icons/fi";
import {RiPencilLine, RiPencilRuler2Fill} from "react-icons/ri";
import {BsFillPersonFill} from "react-icons/bs"
import {HiFolder, HiFolderAdd, HiDocumentReport} from "react-icons/hi";
import { FaUsers } from "react-icons/fa";
import {
    CgChevronLeft, CgChevronRight,
    FaUmbrellaBeach,
    FaUniversity
} from "react-icons/all";
import membersRepository from "../../repository/membersRepository";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";


const Sidebar = () => {

    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)
    // const [users, setUsers] = useState([]);
    const [members, setMembers] = useState([]);
    // const [memberOn, setMember] = useState();

    const fetchMember = async () => {
        await membersRepository.fetchMembers()
        .then((data) => {
            setMembers(data.data.result);
            });

            // for (var i = 0; i < members.length  ; i++)
            // {
            //     membersRepository.getOneMember(members[0].id).then((res) => {
            //         setMember(res.data);
            //     })
            // }
            
    }
    
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);

    };
    useEffect(() => {
        
     
      
        document.querySelectorAll(".ff").forEach((elem) => {
            if (elem.childNodes[0].childNodes[1].children[0].href === window.location.href) {
                if (!elem.className.includes("active")) {
                    elem.className += " active";
                }
            }else{
                if(elem.className.includes("active")){
                    elem.className = elem.className.replace(" active", "");
                }
            }
        });
    })

    const changeColor = (e) => {

        let currClass = e.nativeEvent.path[3].classList.value;
        document.querySelectorAll(".ff").forEach((elem) => {
            if (elem.className === currClass && !elem.className.includes(" active")) {
                elem.className += " active";
            } else if (elem.className.includes(" active") && elem.className !== currClass) {
                elem.className = elem.className.replace(" active", "");
            }
            

        });

    }

    // const memberOne = () => {
    //     for (var i = 0; i < members.length ; i++)
    //     {
    //         membersRepository.getOneMember(members[i].id).then((res) => {
    //             setMember(res.data);
    //         })
    //     }
    // }

    const removeLocalStorage = () => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem("email");
        localStorage.removeItem('role');
        localStorage.removeItem('uniqueId');
        localStorage.removeItem('username');
        localStorage.removeItem('nameSurname');
        localStorage.removeItem('id');
        localStorage.removeItem('memberId');
        window.location.href="/";
    }


    React.useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1520) {
                if (!document.querySelector('.pro-sidebar').className.includes("collapsed")) {
                    document.querySelector('.pro-sidebar').className += " collapsed";
                }
            } else {
                if (document.querySelector('.pro-sidebar').className.includes("collapsed")) {
                    document.querySelector('.pro-sidebar').className = document.querySelector('.pro-sidebar').className.replace("collapsed", "");
                }
            }
        }

        window.addEventListener('resize', handleResize)

    })

   
    

    React.useEffect(() => {
        
        fetchMember()
        return () => {
        }
    }, [0])

    // React.useEffect(() => {
        
    //     memberOne()
    //     return () => {
    //     }
    // }, [0])

    return (
        <>
            <div id="header" style={localStorage.getItem('email')===null?{display:"none"}:{}}>

                {/* collapsed props to change menu size using menucollapse state */}
                <ProSidebar collapsed={menuCollapse}>
                    <SidebarHeader>
                        {/*<div className="logotext"  style={{display: "flex", alignItems: "center"}}>*/}
                        {/*    /!* small and big change using menucollapse state *!/*/}
                        {/*    <img src={logo} alt="Logo" id="logo"/>*/}
                        {/*    <p>{menuCollapse ? "Timesheet" : "Timesheet"}</p>*/}
                        {/*</div>*/}
                        <div className="closemenu" onClick={menuIconClick}>
                            {/* changing menu collapse icon on click */}
                            {menuCollapse ? (<CgChevronRight/>) : (<CgChevronLeft/>
                            )}
                        </div>
                    </SidebarHeader>
                    <SidebarContent>

                        <Menu iconShape="square">

                            <MenuItem style={localStorage.getItem('role')==="ROLE_ADMIN"?{visibility:"visible"}:{display:"none"}} className="ff member" onClick={changeColor} active={true}
                                      icon={<BsFillPersonFill/>}>Users<Link to="/users"/></MenuItem>
                            <MenuItem style={localStorage.getItem('role')==="ROLE_ADMIN"?{visibility:"visible"}:{display:"none"}} className="ff roles" onClick={changeColor} active={true}
                                      icon={<FaUsers/>}>Project Roles<Link to="/memberTypes"/></MenuItem>
                            <MenuItem style={localStorage.getItem('role')==="ROLE_ADMIN"?{visibility:"visible"}:{display:"none"}} className="ff holiday" onClick={changeColor}
                                      icon={<FaUmbrellaBeach/>}>Holidays<Link to="/holidays"/></MenuItem>
                            <MenuItem style={localStorage.getItem('role')==="ROLE_ADMIN"?{visibility:"visible"}:{display:"none"}} className="ff university" onClick={changeColor}
                                      icon={<FaUniversity/>}>Institutions<Link to="/universities"/></MenuItem>
                                      {/* {member.memberType.name === "Manager" ? (<MenuItem className="ff projects" onClick={changeColor} icon={<HiFolderAdd/>}>Projects <Link
                                to="/projects"/></MenuItem>) : ("") } */}
                                {/* {memberOne.memberType.id === 1 ? <MenuItem className="ff projects" onClick={changeColor} icon={<HiFolderAdd/>}>Projects <Link
                                 to="/projects"/></MenuItem> : ""} */}
                                 
                                <MenuItem className="ff projects" onClick={changeColor} icon={<HiFolderAdd/>}>Projects <Link
                                 to="/projects"/></MenuItem>

                               
                                      {/* {members.map((member) => {
                                          if(member.memberType.id === 1) { return (
                                <MenuItem className="ff projects" onClick={changeColor}
                                icon={<HiFolder/>}>Projects<Link to="/projects"/></MenuItem> ) }
                                else { 
                                    return ("");
                                }
                                })} */}
                                
          
                            {/* <SubMenu
            style={{backgroundColor:"none"}}
            title="Timesheets"
            icon={<RiPencilLine />}

          >
            <MenuItem onClick={changeColor}>My Timesheets<Link to="/my-timesheets"/></MenuItem>
            {localStorage.getItem('role')==="ROLE_ADMIN" || localStorage.getItem('role')==="ROLE_FINANCE" ? (
            <MenuItem onClick={changeColor}>Project Timesheets<Link to="/timesheets/project-timesheets"/></MenuItem>) : ("")}

          </SubMenu> */}
                            <MenuItem className="ff timesheets" onClick={changeColor}
                                      icon={<RiPencilLine/>}>My Timesheets<Link to="/my-timesheets"/></MenuItem>
                            {/* {localStorage.getItem('role')==="ROLE_ADMIN" || localStorage.getItem('role')==="ROLE_FINANCE" ? (
                                <MenuItem className="ff prtimesheets" onClick={changeColor}
                                          icon={<RiPencilRuler2Fill/>}>Project Timesheets<Link to="/timesheets"/></MenuItem> ) : ("")} */}
                            <MenuItem className="ff timesheet" onClick={changeColor}
                                      icon={<HiDocumentReport/>}>Reports<Link to="/reports"/></MenuItem>

                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <Menu iconShape="square">
                            <MenuItem onClick={removeLocalStorage} icon={<FiLogOut/>}>Logout</MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>
            </div>
        </>
    );
};

export default Sidebar;