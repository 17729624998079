import React, { useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import TimesheetService from "../../repository/timesheetsRepository";
import Login from "../Login/Login";
import EditTimeSheet from "./EditTimeSheet";
import MemberRepository from "../../repository/membersRepository";
import Toggle from "../Toggle";
import Loader from "../loader/Loader";
const MyTimeSheets = (props) => {
  const history = useHistory();
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [timeSheets, setTimeSheets] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [projectName, setProjectName] = React.useState();
  const [workPackageName, setWorkPackageName] = React.useState();
  const [taskName, setTaskName] = React.useState();
  const [dayOfMonth, setDayOfMonth] = React.useState();
  const [totalElements, setTotalElements] = React.useState(0);
  const [workingHours, setWorkingHours] = React.useState();
  const [userName, setUserName] = React.useState();
  const [userSurname, setUserSurname] = React.useState();
  const [id, setId] = React.useState();
  const [members, setMembers] = React.useState([]);
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [users, setUsers] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [size, setSize] = React.useState("default");
  const [disabled, setDisabled] = React.useState(false);
  const [lockTimeSheetHelper, setLockTimeSheetHelper] = React.useState({
    userId: null,
    year: year,
    month: month,
  });
  const [sumHours, setSumHours] = React.useState(0);
  const [updateHours, setUpdateHours] = React.useState([]);
  const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading]=React.useState(false);
  const [isDisabled, setIsDisabled]=React.useState(false)
  
  const timeoutErrorMessage = () => {
    let timer;
    setShowErrorMessage(true);
    setErrorMessage("You can not set working hours on holidays or weekends and you can not set more than 8 hours per day.");
    if (clearPreviousTimeout !== undefined) {
      clearTimeout(clearPreviousTimeout);
    }
    timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 3000);
    setClearPreviousTimeout(timer);
  };
  const setHoursForTimesheetTask = (id) => {
    let taskId = timeSheets.find(item =>item.taskId===id)
    updateHours.map((hour)=>{
      if(hour.taskId===id){
        TimesheetService.setHoursOfTimeSheetTask(updateHours).then((res) => {
          getAllTimeSheetsByMonthAndYear(page);
          calculateSumOfHours();
          setUpdateHours([])
    
        })  .catch((error) => {
          if (error.message === "Request failed with status code 409") {
            timeoutErrorMessage()
            setUpdateHours([])
          }
        });
      }
      else{
        
      }
     
    })
      
     

  };

  const handleChangeWorkingHours = (e, taskId, timeSheetId, dayOfMonth) => {
    // if(updateHours.length!==0){
    //   var list = updateHours.filter((item)=>{
    //     return item.taskId !== taskId && item.timesheetId !== timeSheetId
    //   })
    //   setUpdateHours(list)
    // }
    setUpdateHours((prevState) => [
      ...prevState.filter((item)=>{
        return item.timesheetId !== timeSheetId
      }),
      {
        taskId: taskId,
        timesheetId: timeSheetId,
        workingHours: e.target.value,
      },
    ]);

    const newState = timeSheets.map(obj => {
      if (obj.taskId === taskId) {

        let changedDayHoursHelper = obj.dayHoursHelper.map(day => {
          if(day.timesheetId == timeSheetId && day.dayOfMonth == dayOfMonth){
            return {...day, hours: e.target.value}
          }

          return day
        })
        return {...obj, dayHoursHelper: changedDayHoursHelper};
      }
      return obj;
    });

    setTimeSheets(newState);

    if (e.target.value > 8) {
      e.preventDefault();
    }
  };
  const handleChangeToggle = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked && userId !== 0) {
      lockTimeSheet();
    } else {
      unLockTimeSheet();
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllTimeSheetsByMonthAndYear(newPage);
  };
  const handleOpenEdit = (e) => {
    const value = e.target.value;
    const json = JSON.parse(value);

    const id = e.target.id;

    setProjectName(json.projectName);
    setWorkPackageName(json.workPackageName);
    setDayOfMonth(json.dayOfMonth);
    setTaskName(json.taskName);
    setWorkingHours(json.workingHours);
    setUserName(json.userName);
    setUserSurname(json.userSurname);
    setId(id);
    setOpen(true);
  };

  const handleCloseEdit = () => {
    setOpen(false);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeMonth = (month) => {
    setMonth(month.value);
    TimesheetService.getTimeSheetsByMonthAndYear(
        page,
        rowsPerPage,
        month.value,
        year,
        userId
    ).then((res) => {
      setTimeSheets(res.data.content);
    });
    calculateSumOfHours();
  };
  const handleChangeYear = (year) => {
    setYear(year.value);
    TimesheetService.getTimeSheetsByMonthAndYear(
        page,
        rowsPerPage,
        month,
        year.value,
        userId
    ).then((res) => {
      setTimeSheets(res.data.content);
    });
    calculateSumOfHours();
  };
  const handleChangeMember = (selected) => {
    setTimeSheets([]);
    setUserId(selected.value);
    setLockTimeSheetHelper({
      ...lockTimeSheetHelper,
      userId: selected.value,
    });
    calculateSumOfHours();
  };
  const calculateSumOfHours = () => {
    TimesheetService.calculateSumOfHours(year, month, userId).then((res) => {
      setSumHours(res.data);
    });
  };
  const getAllTimeSheetsByMonthAndYear = (page) => {
    TimesheetService.getTimeSheetsByMonthAndYear(
        page,
        rowsPerPage,
        month,
        year,
        userId
    ).then((res) => {
      for (var i = 0; i < res.data.content.length; i++) {
        if (res.data.content[i].locked) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
      setTimeSheets(res.data.content);
      setTotalElements(res.data.totalElements);
    });
  };

  const bodyEdit = (
      <EditTimeSheet
          handleCloseEdit={handleCloseEdit}
          onUpdate={() => {
            getAllTimeSheetsByMonthAndYear(page);
            handleCloseEdit();
          }}
          id={id}
          projectName={projectName}
          workPackageName={workPackageName}
          taskName={taskName}
          dayOfMonth={dayOfMonth}
          workingHours={workingHours}
          userName={userName}
          userSurname={userSurname}
      ></EditTimeSheet>
  );
  const getAllMembers = () => {
    MemberRepository.fetchUsers().then((res) => {
      setMembers(res.data);
    });
  };
  const getAllForTimesheets = () => {
    MemberRepository.getAllForTimesheets().then((res) => {
      setUsers(res.data);
    });
  };
  const getLoggedInfo = () => {
    MemberRepository.getLoggedUserInfo().then((res) => {
    });
  };
  const lockTimeSheet = () => {
    const timesheetLockHelper = {
      userId: lockTimeSheetHelper.userId,
      year: year,
      month: month,
    };
    TimesheetService.lockTimeSheet(timesheetLockHelper).then((res) => {
      getAllTimeSheetsByMonthAndYear(page);
    });
  };
  const unLockTimeSheet = () => {
    const timesheetLockHelper = {
      userId: lockTimeSheetHelper.userId,
      year: year,
      month: month,
    };
    TimesheetService.unLockTimeSheet(timesheetLockHelper).then((res) => {
      getAllTimeSheetsByMonthAndYear(page);
    });
  };
  function isNumber(e) {
    if (e.target.value > 8 || e.key > 8) {
      e.preventDefault();
    }
  }
  const generateTimesheets=()=>{
    const reportHelper={
      userId:userId,
    }
    setIsLoading(true)
    setIsDisabled(true)
    TimesheetService.generateTimeSheetsPersonal(reportHelper).then(res=>{
      setIsLoading(false);
      setIsDisabled(false)
    }).catch(error=>{
      setIsLoading(false)
    })
  }
  useEffect(() => {
    getAllTimeSheetsByMonthAndYear(page);
    getLoggedInfo();
    getAllMembers();
    getAllForTimesheets();
    calculateSumOfHours();

  }, [rowsPerPage, userId, lockTimeSheetHelper, year, month]);
  if (localStorage.getItem("email") !== null) {
    const optionMembers = members.map((member) => {
      return {
        value: member.id,
        label:
            member.firstName +
            " " +
            member.lastName +
            " " +
            "(" +
            member.email +
            ")",
      };
    });
    const optionUsers = users.map((user) => {
      return {
        value: user.id,
        label:
            user.firstName + " " + user.lastName + " " + "(" + user.email + ")",
      };
    });
    const optionsMonths = [
      { value: "1", label: `January` },
      { value: "2", label: `February` },
      { value: "3", label: `March` },
      { value: "4", label: `April` },
      { value: "5", label: `May` },
      { value: "6", label: `June` },
      { value: "7", label: `July` },
      { value: "8", label: `August` },
      { value: "9", label: `September` },
      { value: "10", label: `October` },
      { value: "11", label: `November` },
      { value: "12", label: `December` },
    ];
    var from = 2015;
    var to = 2028;
    var optionsYears = [];
    for (var y = from; y <= to; y++) {
      optionsYears.push({ value: y, label: y });
    }
    return (
        <div className="container">
          <Modal
              open={open}
              onClose={handleCloseEdit}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
          >
            {bodyEdit}
          </Modal>
          <div className="row pt-3">
            <Select
                name="user"
                placeholder={new Date().toLocaleString("default", {
                  month: "long",
                })}
                className="col-6"
                isMulti={false}
                onChange={handleChangeMonth}
                options={optionsMonths}
            ></Select>
            <Select
                name="user"
                placeholder={new Date().getFullYear()}
                className="col-6"
                isMulti={false}
                onChange={handleChangeYear}
                options={optionsYears}
            ></Select>
          </div>
          <div className="row pt-3">
            {localStorage.getItem("role") !== "ROLE_ADMIN" ? (
                <Select
                    name="user"
                    placeholder={
                        localStorage.getItem("nameSurname") +
                        " " +
                        "(" +
                        localStorage.getItem("email") +
                        ")"
                    }
                    className="col-6"
                    isMulti={false}
                    onChange={handleChangeMember}
                    options={optionUsers}
                ></Select>
            ) : (
                <Select
                    name="user"
                    placeholder={localStorage.getItem("nameSurname") +
                        " " +
                        "(" +
                        localStorage.getItem("email") +
                        ")"}
                    className="col-6"
                    isMulti={false}
                    onChange={handleChangeMember}
                    options={optionMembers}
                ></Select>
            )}

            {localStorage.getItem("role") === "ROLE_ADMIN" && (
                <div>

                  {/* <button
                className="btn btn-md btn-info ml-3"
                onClick={lockTimeSheet}
              >
                Lock Timesheet
              </button>

              <button
                className="btn btn-md btn-success ml-3"
                onClick={unLockTimeSheet}
              >
                Unlock Timesheet
              </button> */}
                  <form className="ml-3">
                    <Toggle
                        checked={checked}
                        text="Locked"
                        size={size}
                        disabled={disabled}
                        onChange={handleChangeToggle}
                        offstyle="btn-secondary"
                        onstyle="btn-danger"
                    />
                  </form>
                </div>
            )}
          </div>
          {localStorage.getItem("role") === "ROLE_ADMIN" &&
              <button className="btn  mt-3 btn-info" onClick={generateTimesheets} disabled={isDisabled}>
                Generate Timesheets
              </button>
          }
          {isLoading ? <Loader/> :
              <div className="row mt-5">
                <div className="col mainCol">
                  <Card className="customCard">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <CardHeader title={"Timesheets"}></CardHeader>
                      <p
                          className="mr-3 mt-3 sumHours"
                          style={
                            lockTimeSheetHelper.userId !== null ||
                            localStorage.getItem("role") !== "ROLE_ADMIN"
                                ? { visibility: "visible" }
                                : { display: "none" }
                          }
                      >
                        Sum of Hours: <b>{sumHours}</b>
                      </p>
                    </div>
                    <CardBody>
                      <div className="table-rep-plugin">
                        <div className="" data-pattern="priority-columns">
                          <TableContainer>
                            <Table>
                              <TableHead>
                                {timeSheets.map((term, i) => {
                                  //  term.dayHoursHelper.sort((a,b)=>a.dayOfMonth - b.dayOfMonth)
                                  return (
                                      <>
                                        {i === 0 && (
                                            <TableRow>
                                              <TableCell></TableCell>
                                              {term.dayHoursHelper.map((day) => {
                                                return (
                                                    <TableCell>{day.dayOfMonth}</TableCell>
                                                );
                                              })}
                                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                                Sum
                                              </TableCell>
                                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                                Set Hours
                                              </TableCell>
                                            </TableRow>
                                        )}

                                        <TableRow
                                            className={
                                              term.locked
                                                  ? "greyRow"
                                                  : term.hasMoreHours
                                                      ? "hasMoreHoursRow"
                                                      : "whiteRow"
                                            }
                                        >
                                          <TableCell>
                                            {term.projectName +
                                                "/" +
                                                term.workPackageName +
                                                "/" +
                                                term.taskName}
                                          </TableCell>
                                          {term.dayHoursHelper.map((day) => {
                                            return (
                                                <TableCell>
                                                  <input
                                                      type="number"
                                                      onWheel={(e) => e.target.blur()}
                                                      min="0"
                                                      max="8"
                                                      step="0.1"
                                                      className={
                                                        day.isWorkingDay
                                                            ? "inputWorkingDay"
                                                            : ""
                                                      }
                                                      style={{
                                                        width: "40px",
                                                        height: "30px",
                                                        textAlign: "center",
                                                        border: "none",
                                                        borderBottom: "1px solid gray",
                                                      }}
                                                      disabled={
                                                          term.locked ||
                                                          day.isWorkingDay === false
                                                      }
                                                      onKeyPress={(event) => {
                                                        isNumber(event);

                                                      }}
                                                      // onClick={() =>
                                                      //   handleClickInput(
                                                      //     term.taskId,
                                                      //     day.timesheetId
                                                      //   )
                                                      // }
                                                      onChange={(e) => {
                                                        handleChangeWorkingHours(
                                                            e,
                                                            term.taskId,
                                                            day.timesheetId,
                                                            day.dayOfMonth
                                                        );
                                                      }}
                                                      value={day.hours}
                                                  />
                                                </TableCell>
                                            );
                                          })}
                                          <TableCell style={{ whiteSpace: "nowrap" }}>
                                            <input
                                                className={
                                                  term.locked
                                                      ? "inputSum"
                                                      : term.hasMoreHours
                                                          ? "inputSumHasMoreHours"
                                                          : ""
                                                }
                                                readOnly
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                  textAlign: "center",
                                                  border: "none",
                                                  borderBottom: "1px solid gray",
                                                }}
                                                value={term.sumOfWorkingHours}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <button
                                                className="btn btn-warning btn-sm"
                                                style={{ whiteSpace: "nowrap" }}
                                                onClick={()=>{setHoursForTimesheetTask(term.taskId)}}
                                                disabled={term.locked}
                                            >
                                              Set Hours
                                            </button>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                  );
                                })}
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <TablePagination
                              component="div"
                              rowsPerPageOptions={[5, 10, 15]}
                              count={totalElements}
                              page={page}
                              onChangePage={handleChangePage}
                              rowsPerPage={rowsPerPage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                          {/* <TableContainer>
                      <Table id="universityTable" className="table tableCustom">
                        <TableHead>
                          <TableRow>
                            <TableCell data-priority="3">Project</TableCell>
                            <TableCell data-priority="3">
                              Work Package
                            </TableCell>
                            <TableCell data-priority="3">Task</TableCell>
                            <TableCell data-priority="3">Day </TableCell>
                            <TableCell data-priority="3">Hours </TableCell>
                            <TableCell data-priority="3">Update </TableCell>
                            <TableCell data-priority="3"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {timeSheets.map((term) => {
                            const timeSheetEdit = JSON.stringify({
                              id: term.id,
                              projectName: term.projectName,
                              workPackageName: term.workPackageName,
                              taskName: term.taskName,
                              dayOfMonth: term.dayOfMonth,
                              workingHours: term.workingHours,
                              userName: term.userName,
                              userSurname: term.userSurname,
                            });
                            return (
                              <TableRow
                                className={
                                  term.locked
                                    ? "greyRow"
                                    : term.hasMoreHours
                                    ? "hasMoreHoursRow"
                                    : "whiteRow"
                                }
                              >
                                <TableCell className="innerCell">
                                  {term.projectName}
                                </TableCell>
                                <TableCell className="innerCell">
                                  {term.workPackageName}
                                </TableCell>
                                <TableCell className="innerCell">
                                  {term.taskName}
                                </TableCell>
                                <TableCell className="innerCell projectManagerCell">
                                  {term.dayOfMonth}
                                </TableCell>
                                <TableCell className="innerCell">
                                  {term.workingHours}
                                </TableCell>
                                <TableCell className="innerCell">
                                  <button
                                    className="btn btn-sm btn-success updateBtn"
                                    id={term.id}
                                    value={timeSheetEdit}
                                    onClick={handleOpenEdit}
                                    disabled={term.locked}
                                  >
                                    Update
                                  </button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={totalElements}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableContainer> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
          }
          {showErrorMessage === true ? (
              <div
                  style={{
                    width: "500px",
                    position: "absolute",
                    top: "40px",
                    right: "2px",
                  }}
                  className="alert alert-danger alert-dismissible fade show mt-3"
                  role="alert"
              >
                {errorMessage}
              </div>
          ) : (
              <div></div>
          )}
        </div>
    );
  } else {
    return <Login />;
  }
};

export default MyTimeSheets;