import {Card, CardBody} from "reactstrap";
import React from "react";
import Select from 'react-select';
import MemberRepository from "../../repository/membersRepository";
import Login from "../Login/Login";
import { convertGridRowsPropToState } from "@material-ui/data-grid";

const UserEdit = (props) => {
    const [formData, updateFormData] = React.useState({
        id:props.id,
        email: props.email,
        firstName: props.firstName,
        lastName: props.lastName,
        username: props.username,
        role: props.role

    });

    let options = [];
    props.roles.map((role)=>{
        options.push({value:role.id,label:role.name})
    })

    const handleChangeSelect = (value, action) => {
        updateFormData({
            ...formData,
            [action.name]: value
        })
        console.log(value)
    }

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }


     const submitForm = async (e) => {
       
       
        e.preventDefault();
        const email = formData.email;
        const firstName = formData.firstName;
        const lastName = formData.lastName;
        const username = "username";    
        const role = {
            name: formData.role.name || formData.role.label,
            deleted: false
        }
       
        const id = formData.id;
        MemberRepository.editUser(id,email,firstName,lastName,username, role);
         window.location="/users";
    }

    const handleCancel = () => {
        props.handleCloseEdit();
    }

    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">

            <div className="row mt-5" >
                <div className="col mainCol">
                    <Card style={{marginTop:"5%"}}>
                        <CardBody>
                            <h3 className="formHeader">Edit User</h3>
                            <form onSubmit={submitForm}>
                                <div className="form-group">
                                    <label htmlFor="name">Email</label>
                                    <input type="text"
                                           className="form-control inputForm"
                                           name="email" required id="email"
                                           onChange={handleChange}
                                           defaultValue={props.email}
                                    />
                                </div>
                                <div className="form-group">

                                    <label htmlFor="dean">First Name</label>
                                    <input type="text" className="form-control inputForm" id="firstName" name="firstName"
                                           required
                                           onChange={handleChange}
                                           defaultValue={props.firstName}
                                    />


                                </div>
                                <div className="form-group">

                                    <label htmlFor="dean">Last Name</label>
                                    <input type="text" className="form-control inputForm" id="lastName" name="lastName"
                                           required
                                           onChange={handleChange}
                                           defaultValue={props.lastName}
                                    />


                                </div>
                                {/* <div className="form-group">

                                    <label htmlFor="dean">Username</label>
                                    <input type="text" className="form-control inputForm" id="username" name="username"
                                           required
                                           onChange={handleChange}
                                           defaultValue={props.username}
                                    />


                                </div> */}
                                <div className="form-group">    
                                    <label>Role</label>
                                    <Select name="role" onChange={handleChangeSelect} options={options} defaultValue={{label:props.role.name, value:props.role.id}}>
                                        
                                    </Select>


                                </div>


                                
                                <button type="button" id="submit" className="btn saveBtn" onClick={submitForm}>Save</button> <br/>
                            </form>
                            <button style={{marginTop:10}} className="btn saveBtn" onClick={handleCancel} type="button">Cancel</button>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>

    )}else{
        return (
            <Login/>
        )
    }

}

export default UserEdit;