import React, {useEffect} from 'react';
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {
    ButtonGroup,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {AiFillDelete} from "react-icons/ai";
import {IoMdAdd} from "react-icons/io";
import HolidaysAdd from "./HolidaysAdd";
import HolidaysDelete from "./HolidaysDelete";
import HolidayEdit from "./HolidayEdit";
import UniversityEdit from "../Universities/UniversityEdit";
import {RiEdit2Fill} from "react-icons/ri";
import Login from "../Login/Login";
import {Dropdown, DropdownButton} from "react-bootstrap";


const HolidaysAll = props => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [openAdd, setOpenAdd] = React.useState(false);

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };


    const [openDelete, setOpenDelete] = React.useState(false);
    const [id, setId] = React.useState();

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };

    const [openEdit, setOpenEdit] = React.useState(false);
    const [name, setName] = React.useState();
    const [date, setDate] = React.useState();

    const handleOpenEdit = (e) => {

        const value = e.target.value;
        const json = JSON.parse(value);
        const id = e.target.id;
        setDate(json.date);
        setName(json.name);
        setId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {

        setOpenEdit(false);
    };


    useEffect(() => {
        filterTableInitial()
        return () => {
        }
    }, [])





    const bodyHolidayAdd =(
        <HolidaysAdd handleCloseAdd={handleCloseAdd} holidays={props.holidays}></HolidaysAdd>
    )

    const bodyHolidayDelete =(
        <HolidaysDelete handleCloseDelete={handleCloseDelete} comesFrom={"parent"} id={id}></HolidaysDelete>

    )
    const bodyHolidayEdit =(
        <HolidayEdit handleCloseEdit={handleCloseEdit} id={id} name={name} date={date}></HolidayEdit>

    )
    const [filteredHolidays, setFilteredHolidays] = React.useState([]);
    const filterTable = (e) => {

        const value = e.target.value;
       

        let currentyear = new Date(). getFullYear().toString()
      
        if (value === null || value === "") {
            let uniList = []
            props.holidays.map(uni => {
                let a = uni.date.split(".")
                let b = a[2]
                if(b===(new Date(). getFullYear().toString()))
                {
                    uniList.push(uni)
                }
                setFilteredHolidays(uniList);
            })
        } else {
            let uniList = []
            props.holidays.map(uni => {
                let a = uni.date.split(".")
                let b = a[2]

                if (b.includes(value))
                {
                    uniList.push(uni)
                }
            })
            if (uniList.length > 0) {
                setFilteredHolidays(uniList);
            } /*else {
                setFilteredHolidays(uniList);
            }*/
        }
    }
    const filterTableInitial=()=>{
        let currentyear = new Date(). getFullYear().toString();
      
        let uniList = []
        props.holidays.map(uni => {
            let a = uni.date.split(".")
            let b = a[2]
            if(b===(new Date(). getFullYear().toString()))
            {
                uniList.push(uni)
            }
            setFilteredHolidays(uniList);
        })
    }

    /* const filterTableByYear = (e) =>{
         const value = new Date(e)
         if (value === null || value === "") {
             setFilteredHolidays(props.holidays);
         } else {
             let uniList = []
             props.holidays.map(uni => {
                 if (uni.date.year===value) {
                     uniList.push(uni)
                 }
             })
             if (uniList.length > 0) {
                 setFilteredHolidays(uniList);
             } else {
                 setFilteredHolidays(props.holidays);
             }

         }
     }*/


    if(localStorage.getItem("email")!==null){

        return (

            <div className="container">
                <Modal
                    open={openAdd}
                    onClose={handleCloseAdd}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"

                >
                    {bodyHolidayAdd}

                </Modal>
                <Modal open={openEdit}
                       onClose={handleCloseEdit}
                       aria-labelledby="simple-modal-title"
                       aria-describedby="simple-modal-description"
                >
                    {bodyHolidayEdit}
                </Modal>
                <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyHolidayDelete}
                </Modal>

                <div className="row mt-5">
                    <div className="col mainCol">
                        <Card className="customCard">
                            <CardHeader title={"Holidays"}></CardHeader>
                            <CardBody>

                                {/* <div>
                                <select id="year" name="year"
                                        onChange={filterTableByYear}>
                                    <option>year</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                </select>


                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-info">
                                        Year
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item value="2020">2020</Dropdown.Item>
                                        <Dropdown.Item value="2021">2021</Dropdown.Item>
                                        <Dropdown.Item value="2022">2022</Dropdown.Item>
                                    </Dropdown.Menu>

                                </Dropdown>

                            </div>
                            <br />
                            <br />
                            <br />*/}
                                <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter by year"/>


                                <div className="table-rep-plugin">
                                    <div
                                        className=""
                                        data-pattern="priority-columns"
                                    >
                                        <TableContainer>
                                            <Table
                                                id="universityTable"
                                                className="table tableCustom"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell data-priority="3">Name</TableCell>
                                                        <TableCell data-priority="3">Date</TableCell>
                                                        <TableCell data-priority="3">
                                                            <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                                <IoMdAdd
                                                                    className="buttonIcon"/>
                                                            </a>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody id="userTableBody">
                                                    {filteredHolidays.length>0?filteredHolidays.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term)=>{

                                                        const nameAndDate = JSON.stringify({
                                                            "name": term.name,
                                                            "date": term.date
                                                        });

                                                        return(
                                                            <TableRow className="innerRow">
                                                                <TableCell className="innerCell">{term.name}</TableCell>
                                                                <TableCell className="innerCell">{term.date}</TableCell>
                                                                <TableCell>
                                                                    <button id={term.id} value={nameAndDate}
                                                                            className="btn btn-sm infoBtn"
                                                                            onClick={handleOpenEdit}><RiEdit2Fill
                                                                        className="buttonIcon"/></button>
                                                                    <button value={term.id} className="btn btn-sm infoBtn"
                                                                            onClick={handleOpenDelete}><AiFillDelete
                                                                        className="buttonIcon"/></button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}):props.holidays.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {

                                                            const nameAndDate = JSON.stringify({
                                                                "name": term.name,
                                                                "date": term.date
                                                            });

                                                            return (
                                                                <TableRow className="innerRow">
                                                                    <TableCell
                                                                        className="innerCell">{term.name}</TableCell>
                                                                    <TableCell
                                                                        className="innerCell">{term.date}</TableCell>
                                                                    <TableCell>
                                                                        <button id={term.id} value={nameAndDate}
                                                                                className="btn btn-sm infoBtn"
                                                                                onClick={handleOpenEdit}><RiEdit2Fill
                                                                            className="buttonIcon"/></button>
                                                                        <button value={term.id} className="btn btn-sm infoBtn"
                                                                                onClick={handleOpenDelete}><AiFillDelete
                                                                            className="buttonIcon"/></button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}

                                                </TableBody>
                                            </Table>
                                            <TablePagination
                                                component="div"
                                                rowsPerPageOptions={[5, 10, 15]}
                                                count={filteredHolidays.length>0?filteredHolidays.length:props.holidays.length}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </TableContainer>

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                </div>

            </div>
        );}else{
        return (
            <Login/>
        )
    }
};



export default HolidaysAll;