import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {Card, CardBody} from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import {IoMdAdd} from "react-icons/io";
import {BsInfo, BsPersonPlusFill} from "react-icons/bs";
import {AiFillDelete} from "react-icons/ai";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import TaskAdd from "./TaskAdd";
import TaskDelete from "./TaskDelete";
import projectRepository from "../../repository/projectRepository";
import membersRepository from "../../repository/membersRepository";
import TaskAssignMembers from "./TaskAssignMembers";
import itemHourRepository from "../../repository/itemHourRepository";
import Login from "../Login/Login";

const TasksByWorkPackage = (props) => {
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    //Implement the useEffect to update it when Projects are completed
    const [projectId, setProjectId] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
   
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [filteredTasks, setTasks] = useState([]);

    const filterTable = (e) => {
        const value = e.target.value;
       

        if (value === null || value === "") {
            setTasks(props.location.state.tasks);
        } else {
            let uniList = []
            props.location.state.tasks.map(uni => {
                if (uni.description.toLowerCase().includes(value.toLowerCase()) || uni.name.toLowerCase().includes(value.toLowerCase())) {
                    uniList.push(uni)

                }
            })
            if (uniList.length > 0) {
                setTasks(uniList);
            } else {
                setTasks(props.location.state.tasks);
            }
        }
    }

    const [id, setId] = React.useState();
    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpenAdd = () => {
        setOpenAdd(true);
    };


    const handleCloseAdd = () => {
        setOpenAdd(false);
    };
    const [openDelete, setOpenDelete] = React.useState(false);

    const handleOpenDelete = (e) => {
        const value = e.target.value;
        setId(value);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {

        setOpenDelete(false);
    };

    const handleClick = (e) => {
        const id = e.target.id;
      
        itemHourRepository.getFindByTask(id).then((snapshot)=>{
           
            history.push({
                pathname:"/itemHours/task/"+id,
                state: {
                    task: snapshot.data,
                    taskId: id
                }
            })
        })

    }
    const bodyAdd = (
        <TaskAdd handleCloseAdd={handleCloseAdd} workPackageId={props.location.state.workPackageId} projectId={props.projectId}></TaskAdd>
    )
    const bodyDelete = (
        <TaskDelete id={id} handleCloseDelete={handleCloseDelete} workPackageId={props.location.state.workPackageId}></TaskDelete>
    )

    const [openAddMember, setOpenAddMember] = React.useState(false);
    const [users, setUsers] = React.useState([]);

    const handleOpenAddMember = (e) => {
        const value = e.target.value;
        const project = e.target.name;

        setId(value);


        // membersRepository.getMembersByAssignedProjectAndNotTask(project, value).then((users) => {

        //     if (users.data.length > 0) {
        //         setUsers(users.data);
        //     } else {
        //         setUsers([]);
        //     }

        // });


        setOpenAddMember(true);
    }
    const handleCloseAddMember = () => {
        setOpenAddMember(false);
    }
useEffect(()=>{
    // console.log(props)
})
    const bodyAddMember = (

        <TaskAssignMembers handleCloseAddMember={handleCloseAddMember} taskId={id} users={users} workPackageId={props.location.state.workPackageId} ></TaskAssignMembers>
    )
    
    if(localStorage.getItem("email")!==null){
    return (
        <div className="container">
            <Modal
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyAdd}

            </Modal>

            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"

            >
                {bodyDelete}

            </Modal>

            <Modal open={openAddMember}
                   onClose={handleCloseAddMember}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
            >
                {bodyAddMember}
            </Modal>

            <div className="row mt-5">
                <div className="col mainCol">
                    <Card className="customCard">
                        <CardHeader title={props.location.state.tasks?props.location.state.tasks[0]?"Tasks" + " " +"-" + " " + props.location.state.tasks[0].workPackage.name:"Task":"Task"}></CardHeader>
                        <CardBody>

                            <input className="filterField" type="text" onKeyUp={filterTable} placeholder="Filter"/>
                            {/*<TextField id="standard-basic" label="Filter" onKeyPress={filterTable}/>*/}

                            <div className="table-rep-plugin">
                                <div
                                    className=""
                                    data-pattern="priority-columns"
                                >
                                    <TableContainer>
                                        <Table
                                            id="universityTable"
                                            className="table tableCustom"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell data-priority="3">Name</TableCell>
                                                    <TableCell data-priority="3">Description</TableCell>
                                                    <TableCell data-priority="3">Max hours</TableCell>

                                                    <TableCell data-priority="3">
                                                        <a className="btn btn-sm infoBtn" onClick={handleOpenAdd}>
                                                            <IoMdAdd
                                                                className="buttonIcon"/>
                                                        </a></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody id="universityTableBody">

                                                {filteredTasks.length > 0 ? filteredTasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell
                                                                className="innerCell">{uni.name}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{uni.description}</TableCell>

                                                            <TableCell
                                                                className="innerCell">{uni.maxHours}</TableCell>

                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn"> Info
                                                                    <BsInfo
                                                                        className="buttonIcon"/></button>
                                                                <button name={uni.workPackage.project.id} value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenAddMember}><BsPersonPlusFill
                                                                    className="buttonIcon"/> Add member</button>
                                                                {/*<button id={uni.id} value={nameAndDean}*/}
                                                                {/*        className="btn btn-sm infoBtn"*/}
                                                                {/*        onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                                {/*    className="buttonIcon"/></button>*/}
                                                                <button value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }) : props.location.state.tasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((uni) => {

                                                    return (
                                                        <TableRow className="innerRow">
                                                            <TableCell
                                                                className="innerCell">{uni.name}</TableCell>
                                                            <TableCell
                                                                className="innerCell">{uni.description}</TableCell>
                                                                
                                                            <TableCell
                                                                className="innerCell">{uni.maxHours}</TableCell>

                                                            <TableCell>
                                                                <button name={uni.name} onClick={handleClick}
                                                                        id={uni.id}
                                                                        className="btn btn-sm infoBtn">
                                                                    <BsInfo
                                                                        className="buttonIcon"/> Info</button>
                                                                <button name={uni.workPackage.project.id} value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenAddMember}><BsPersonPlusFill
                                                                    className="buttonIcon"/> Add member</button>
                                                                {/*<button id={uni.id} value={nameAndDean}*/}
                                                                {/*        className="btn btn-sm infoBtn"*/}
                                                                {/*        onClick={handleOpenEdit}><RiEdit2Fill*/}
                                                                {/*    className="buttonIcon"/></button>*/}
                                                                <button value={uni.id}
                                                                        className="btn btn-sm infoBtn"
                                                                        onClick={handleOpenDelete}><AiFillDelete
                                                                    className="buttonIcon"/> Delete</button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[5, 10, 15]}
                                            count={filteredTasks.length > 0 ? filteredTasks.length : props.location.state.tasks.length}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />

                                    </TableContainer>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>
        </div>
    )}else{
        return (<Login/>)
    }

}
export default TasksByWorkPackage;