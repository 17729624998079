import TimesheetService from "../../repository/timesheetsRepository";
import { Card, CardBody } from "reactstrap";
import React, { useEffect } from "react";
import Login from "../Login/Login";
const EditTimeSheet = (props) => {
  const [formData, updateFormData] = React.useState({
    id: props.id,
    projectName: props.projectName,
    workPackageName: props.workPackageName,
    taskName: props.taskName,
    dayOfMonth: props.dayOfMonth,
    workingHours: props.workingHours,
    userName:props.userName,
    userSurname:props.userSurname
  });
  const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  const timeoutErrorMessage = () => {
    let timer;
    setShowErrorMessage(true);
    setErrorMessage("You can not set working hours on holidays or weekends and you can not set more than 8 hours per day.");
    if (clearPreviousTimeout !== undefined) {
      clearTimeout(clearPreviousTimeout);
    }
    timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 3000);
    setClearPreviousTimeout(timer);
  };
  const submitForm = async (e) => {
    e.preventDefault();

    const timeSheetHelper = {
      id: formData.id,
      projectName: formData.projectName,
      workPackageName: formData.workPackageName,
      taskName: formData.taskName,
      dayOfMonth: formData.dayOfMonth,
      workingHours: formData.workingHours,
    };
    TimesheetService.setHoursOfTimeSheetTask(timeSheetHelper)
      .then((res) => {
        // window.location = "/my-timesheets";
        if (props.onUpdate()) {
          props.onUpdate();
        }
        handleCancel();
      })
      .catch((error) => {
        debugger;
        if (error.message === "Request failed with status code 409") {
          timeoutErrorMessage()
        }
      });
  };
  useEffect(() => {
  });
  const handleCancel = () => {
    props.handleCloseEdit();
  };
  function isNumber(e) {
    if (
      e.key > 8 ||
      formData.workingHours + e.key > 8 ||
      e.key + formData.workingHours > 8
    ) {
      e.preventDefault();
    }
  }
  if (localStorage.getItem("email") !== null) {
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col mainCol">
            <Card style={{ marginTop: "5%" }}>
              <CardBody>
                <h3 className="formHeader">Set Working Hours</h3>
                <form onSubmit={submitForm}>
                  <div className="form-group">
                    <label htmlFor="name">Project</label>
                    <input
                      type="text"
                      className="form-control inputForm"
                      name="projectName"
                      required
                      id="projectName"
                      readOnly
                      onChange={handleChange}
                      defaultValue={props.projectName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dean">Work Package</label>
                    <input
                      type="text"
                      className="form-control inputForm"
                      name="workPackageName"
                      required
                      readOnly
                      onChange={handleChange}
                      defaultValue={props.workPackageName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dean">Task</label>
                    <input
                      type="text"
                      className="form-control inputForm"
                      name="lastName"
                      required
                      readOnly
                      onChange={handleChange}
                      defaultValue={props.taskName}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="dean">Name</label>
                    <input
                      type="text"
                      className="form-control inputForm"
                      name="lastName"
                      required
                      readOnly
                      onChange={handleChange}
                      defaultValue={props.userName}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="dean">Surname</label>
                    <input
                      type="text"
                      className="form-control inputForm"
                      name="lastName"
                      required
                      readOnly
                      onChange={handleChange}
                      defaultValue={props.userSurname}
                    />
                  </div> */}

                
                  <div className="form-group">
                    <label htmlFor="dean">Day</label>
                    <input
                      type="text"
                      className="form-control inputForm"
                      name="dayOfMonth"
                      required
                      readOnly
                      onChange={handleChange}
                      defaultValue={props.dayOfMonth}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dean">Working Hours</label>
                    <input
                      id="workingHours"
                      type="number"
                      min="1"
                      max="8"
                      className="form-control inputForm"
                      name="workingHours"
                      onKeyPress={(event) => {
                        isNumber(event);
                      }}
                      required
                      onChange={handleChange}
                      defaultValue={props.workingHours}
                    />
                  </div>
                  <button
                    type="button"
                    id="submit"
                    className="btn saveBtn"
                    onClick={submitForm}
                  >
                    Save
                  </button>{" "}
                  <br />
                </form>
                <button
                  style={{ marginTop: 10 }}
                  className="btn saveBtn"
                  onClick={handleCancel}
                  type="button"
                >
                  Cancel
                </button>
              </CardBody>
            </Card>
          </div>
        </div>
        {showErrorMessage === true ? (
          <div
            style={{
              width: "500px",
              position: "absolute",
              top: "40px",
              right: "2px",
            }}
            className="alert alert-danger alert-dismissible fade show mt-3"
            role="alert"
          >
            {errorMessage}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  } else {
    return <Login />;
  }
};
export default EditTimeSheet;
