import React, { isValidElement, useEffect } from "react";
import membersRepository from "../../repository/membersRepository";
import timesheetsRepository from "../../repository/timesheetsRepository";
import { Card, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Login from "../Login/Login";
import ProjectService from "../../repository/projectRepository";

const ProjectMembersAdd = (props) => {
  const history = useHistory();
  const [fields, setFields] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState(props.members);
  const [userSelected, setUserSelected] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [clearPreviousTimeout, setClearPreviousTimeout] = React.useState();
  const [projectHelper, setProjectHelper] = React.useState({
    projectId: props.projectId,
    userIds: [],
    memberTypeId:null
  });
  const handleChange = (selected) => {
    let listUserIds = [];
    setUserSelected(selected);
    for (var i = 0; i < selected.length; i++) {
      listUserIds.push(selected[i].value);
    }
    setProjectHelper({
      ...projectHelper,
      userIds: listUserIds,
    });
    console.log(listUserIds);
  };
  const timeoutErrorMessage = () => {
    let timer;
    setShowErrorMessage(true);
    setErrorMessage("You have already added that user!");
    if (clearPreviousTimeout !== undefined) {
      clearTimeout(clearPreviousTimeout);
    }
    timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 3000);
    setClearPreviousTimeout(timer);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    // const users = document.querySelectorAll('.users');
    // const memberTypes = document.querySelectorAll('.memberTypes');
    // const rates = document.querySelectorAll('input[name="hourlyRate"]');
    // let done = 0;
    // for (var i = 0; i < users.length; i++) {
    //     const user = users[i].lastChild.lastChild.value;
    //     console.log(users[i].lastChild.lastChild.value)
    //     const memberType = memberTypes[i].lastChild.value;
    //     const rate = rates[i].value;
    //     let addedMembers = false;
    //     if (user !== 0 && memberType !== 0 && rate) {
    //         membersRepository.getUser(user).then((snapshot1) => {
    //             console.log("snapshot1", snapshot1.data[0])
    //             membersRepository.getMemberType(memberType).then((snapshot) => {
    //                 timesheetsRepository.getProject(props.projectId).then((snapshot2) => {
    //                     membersRepository.addMember(snapshot1.data[0], snapshot.data.result, rate, snapshot2.data).then(() => {
    //                         addedMembers = true
    //                         done = done + 1;
    //                         if (done === users.length) {
    //                             window.location = "/projects";
    //                         }
    //                     })
    //                 });
    //             });
    //         });
    //     } else {
    //         done = done + 1;
    //         if (done === users.length) {
    //             window.location = "/projects";
    //         }
    //     }

    // }
    const project = {
      projectId: projectHelper.projectId,
      userIds: projectHelper.userIds,
      memberTypeId: projectHelper.memberTypeId
    };
    ProjectService.addMemberToProject(project)
      .then((res) => {
        console.log(res.data);
        window.location = "/projects";
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 500") {
          timeoutErrorMessage();
        }
      });
  };
  const getAllUsers = () => {
    membersRepository.fetchUsers().then((res) => {
      console.log("users", res.data);
      setUsers(res.data);
    });
  };
  useEffect(() => {
    getAllUsers();
  }, []);
  const handleCancel = () => {
    props.handleCloseAddMember();
  };
const handleChangeMemberType=(selected)=>{
  console.log(selected)
setProjectHelper({
  ...projectHelper,
  memberTypeId:selected.value
})
}
  const addMemberField = (e) => {
    let newFields = [...fields];
    newFields.push(1);
    setFields(newFields);
    const users = document.querySelectorAll('select[name="user"]');
    // if(users.length>0){
    // const value = users[users.length-1].value;
    // membersRepository.getUsersNotContainingUsername(value).then((snapshot)=>{
    //     setFilteredUsers(snapshot.data);
    // });}

    document.querySelector("#submit").style.visibility = "visible";
    document.querySelector("#cBody").style.height = "500px";
  };

  let optionUsers = [];
  let optionPosition = [];
  let listUserIds = [];
  if (filteredUsers.length > 0) {
    filteredUsers.map((user) => {
      optionUsers.push({
        value: user.user.id,
        label:
          user.user.firstName +
          " " +
          user.user.lastName +
          " (" +
          user.user.email +
          ") ",
      });
      listUserIds.push(user.user.id);
      // console.log(listUserIds)
      // setProjectHelper({
      //     ...projectHelper,
      //    userIds:listUserIds
      // })
    });
  } else {
    props.users.map((user) => {
      optionUsers.push({
        value: user.user.id,
        label:
          user.user.firstName +
          " " +
          user.user.lastName +
          " (" +
          user.user.email +
          ") ",
      });
    });
  }

  props.memberType.map((type) => {
    optionPosition.push({ value: type.id, label: type.name });
  });
  if (localStorage.getItem("email") !== null) {
    const userOptions = users.map((user) => ({
      value: user.id,
      label: user.firstName + " " + user.lastName + " (" + user.email + ") ",
    }));
    return (
      <div className="container">
        {/*{props.location && props.location.state.error && props.location.state.error === "Invalid data" ? <div className="alert alert-danger" role="alert">*/}
        {/*    {props.location.state.error}*/}
        {/*</div>: props.location && props.location.state.error && <div className="alert alert-success" role="alert">*/}
        {/*    {props.location.state.error}*/}
        {/*</div>}*/}
        <div className="row mt-5">
          <Card style={{ marginLeft: "25%" }}>
            <CardBody id="cBody" style={{ overflowY: "scroll" }}>
              <h3 className="formHeader">Add Member</h3>
              <div className="col formMember">
                {/*<button style={{marginBottom: 10}} type="button" id="addMember" className="btn saveBtn"*/}
                {/*        onClick={addMemberField}>Add*/}
                {/*    Member*/}
                {/*</button>*/}
                <form onSubmit={onFormSubmit}>
                  <div
                    style={{
                      borderBottom: "1px solid #8e8e8e",
                      marginBottom: 10,
                    }}
                  >
                    <div className="form-group">
                      <label>User</label>
                      <Select
                        name="userIds"
                        className="users"
                        isMulti
                        options={userOptions}
                        onChange={handleChange}
                        value={userSelected}
                      ></Select>
                    </div>
                    <div className="form-group">
                      <label>Member Type</label>
                      <Select
                        name="memberType"
                        className="memberTypes"
                        onChange={handleChangeMemberType}
                        options={optionPosition}
                      ></Select>
                    </div>
                    <div className="form-group">
                      <label>Hourly Rate</label>
                      <input
                        type="text"
                        className="form-control inputForm"
                        id="hourlyRate"
                        name="hourlyRate"
                        placeholder="Hourly Rate"
                      />
                    </div>
                  </div>

                  {/* <button style={{marginBottom: 10, marginLeft: "20%"}} type="button" id="addMember"
                                    className="btn addMemberBtn"
                                    onClick={addMemberField}>Add
                                Member
                            </button> */}

                  <button
                    type="submit"
                    id="submit"
                    onClick={onFormSubmit}
                    className="btn saveBtn"
                  >
                    Save
                  </button>
                  <br />
                  <br />
                  <button
                    type="submit"
                    id="submit"
                    className="btn saveBtn"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <br />
                </form>
              </div>
            </CardBody>
          </Card>
        </div>
        {showErrorMessage === true ? (
          <div
            style={{
              width: "500px",
              position: "absolute",
              top: "40px",
              right: "2px",
            }}
            className="alert alert-danger alert-dismissible fade show mt-3"
            role="alert"
          >
            {errorMessage}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  } else {
    return <Login />;
  }
};
export default ProjectMembersAdd;
