import React from 'react';
import {Card, CardBody} from "reactstrap";

import membersRepository from "../../repository/membersRepository";
import timesheetsRepository from "../../repository/timesheetsRepository";


const MemberAdd = props => {
    const [formData, updateForDat] = React.useState({
        user: 1,
        memberType: 1,
        hourlyRate: "",
        project: 1,
    })
   

    const handleChange = (e) =>{
        updateForDat({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const user = formData.user;
       
        const memberType = formData.memberType;
        const hourlyRate = formData.hourlyRate;
        const project = formData.project;
        console.log("project", project)
        membersRepository.getUser(user).then((snapshot1) => {
            membersRepository.getMemberType(memberType).then((snapshot) => {
                timesheetsRepository.getProject(project).then((snapshot2) => {
                    membersRepository.addMember(snapshot1.data[0], snapshot.data.result, hourlyRate, snapshot2.data.result)
                    window.location="/members"
                })

            })
        })

    }

    return (
        <div className="container">

            <div className="row mt-5" >
                <Card style={{marginLeft:"20%",marginTop:"10%"}}><CardBody>
                    <h3 className="formHeader">Add Member</h3>
                    <div className="col">

                        <form onSubmit={onFormSubmit}>
                            <div className="form-group">
                                <label>User</label>
                                <select required name="user" className="form-control" onChange={handleChange}>
                                    <option selected>---------</option>
                                    {props.users.map((term) =>
                                        <option value={term.username}>{term.username}</option>
                                    )}
                                </select>


                            </div>
                            <div className="form-group">
                                <label>Member Type</label>
                                <select required name="memberType" className="form-control" onChange={handleChange}>
                                    <option selected>---------</option>
                                    {props.memberType.map((term) =>
                                        <option value={term.id}>{term.name}</option>
                                    )}
                                </select>


                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control inputForm" id="hourlyRate"
                                       name="hourlyRate" placeholder="Hourly Rate" required
                                       onChange={handleChange}/>


                            </div>
                            <div className="form-group">
                                <label>Project</label>
                                <select name="project" required className="form-control" onChange={handleChange}>
                                    <option selected>---------</option>
                                    {props.projects.map((term) =>
                                        <option value={term.id}>{term.name}</option>
                                    )}
                                </select>


                            </div>
                            <button type="submit" id="submit" className="btn saveBtn">Save</button> <br/>

                        </form>
                    </div>
                </CardBody></Card>
            </div>
        </div>
    );
};



export default MemberAdd;

