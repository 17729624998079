import {Card, CardBody} from "reactstrap";
import React from "react";
import Select from 'react-select';
import MemberRepository from "../../repository/membersRepository";
import Login from "../Login/Login";


const ProjectListMembersEdit = (props) => {
    const [formData, updateFormData] = React.useState({
        id:props.id,
        email: props.email,
        firstName: props.firstName,
        lastName: props.lastName,
        hourlyRate: props.hourlyRate,
        memberTypes: props.memberTypes,
        memberType: props.memberType,
        memberTypeId: props.memberTypeId,
    });

    let options = [];
    props.memberTypes.map((role)=>{
        options.push({value:role.id,label:role.name})
    })

    const handleChangeSelect = (value, action) => {
        updateFormData({
            ...formData,
            [action.name]: value
        })
    }

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }


     const submitForm = async (e) => {
        e.preventDefault();
        // const email = formData.email;
        // const firstName = formData.firstName;
        // const lastName = formData.lastName;
        // const username = "username";    
        // const role = {
        //     name: formData.role.label,
        //     deleted: false
        // }
        const id = formData.id;
        const projectId = props.projectId;
        const memberTypeId = formData.memberTypeId.value;
        const hourlyRate = formData.hourlyRate;
        
        // const id = formData.id;
         await MemberRepository.editMember(id,memberTypeId,projectId,hourlyRate);
        window.location="/projects";
    }

    const handleCancel = () => {
        props.handleCloseEdit();
    }

    if(localStorage.getItem("email")!==null){
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col mainCol">
            <Card>
              <CardBody>
                <h3 className="formHeader">Edit Member</h3>
                <form onSubmit={submitForm}>
                  <div className="form-group">
                    <label htmlFor="dean">First Name</label>
                    <input
                    style={{cursor:'not-allowed'}}
                      type="text"
                      className="form-control inputForm"
                      name="firstName"
                      id="firstName"
                      defaultValue={props.firstName}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dean">Last Name</label>
                    <input
                    style={{cursor:'not-allowed'}}
                      type="text"
                      className="form-control inputForm"
                      id="lastName"
                      name="lastName"
                      defaultValue={props.lastName}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Email</label>
                    <input
                    style={{cursor:'not-allowed'}}
                      type="text"
                      className="form-control inputForm"
                      id="email"
                      name="email"
                      defaultValue={props.email}
                      disabled
                    />
                  </div>
                  {/* <div className="form-group">

                                    <label htmlFor="dean">Username</label>
                                    <input type="text" className="form-control inputForm" id="username" name="username"
                                           required
                                           onChange={handleChange}
                                           defaultValue={props.username}
                                    />


                                </div> */}
                  <div className="form-group">
                    <label>Project Role</label>
                    <Select
                      name="memberTypeId"
                      onChange={handleChangeSelect}
                      options={options}
                      defaultValue={{
                        label: props.memberType.name,
                        value: props.memberType.id,
                      }}
                    ></Select>
                  </div>
                  <div className="form-group">

                                    <label htmlFor="dean">Rate</label>
                                    <input type="text" className="form-control inputForm" id="hourlyRate" name="hourlyRate"
                                          
                                           onChange={handleChange}
                                           defaultValue={props.hourlyRate}
                                    />


                                </div>
                  <button
                    type="button"
                    id="submit"
                    className="btn saveBtn"
                    onClick={submitForm}
                  >
                    Save
                  </button>{" "}
                  <br />
                </form>
                <button
                  style={{ marginTop: 10 }}
                  className="btn saveBtn"
                  onClick={handleCancel}
                  type="button"
                >
                  Cancel
                </button>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );}else{
        return (
            <Login/>
        )
    }

}

export default ProjectListMembersEdit;