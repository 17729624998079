    import React from "react";
    import TimesheetService from "../../repository/timesheetsRepository";
    import {Card, CardBody} from "reactstrap";
    import projectRepository from "../../repository/projectRepository";
    import {useHistory} from "react-router-dom";
    import Login from "../Login/Login";

    const WorkPackageDelete = (props) => {
        const history = useHistory();
        const [message, setErrorMessage] = React.useState("");
        const [error, setError] = React.useState(false);
        const [formData,setFormData] = React.useState({
            id:props.id,

        });

        const handleCancel = () => {
            props.handleCloseDelete();
        }

        const submitForm = (e) => {
            e.preventDefault();
            const id = formData.id;
          
            
            projectRepository.getTasksByWorkPackage(id).then((snapshot)=>{
                if (snapshot.data.length === 0){

                    TimesheetService.deleteWorkPackage(id).then(()=>{
                        if(props.comesFrom==="root"){
                            window.location="/work_packages";
                        }else{
                            TimesheetService.getAllWorkingPackagesByProject(props.projectId).then((snapshot) => {
                                let projectsWPs = [];
                                if (snapshot.data.length > 0) {
                                    projectsWPs=snapshot.data;
            
                                }
                                history.push({
                                    pathname: '/project/work_packages/'+props.projectId,
                                    state:{
                                        workPackages: projectsWPs,
                                        projectId: props.projectId
                                    }
                                });
                                history.go(0);
            
                            });
                        }
                    });
                
                }else{
                        setError(true);
                    setErrorMessage("You can not delete this work package, there are still tasks in this package!");
                }
            });
            

        }
        if (localStorage.getItem("email")!==null){
        return(
            <div className="container">

                <div className="row mt-5" >
                    <div className="col mainCol">
                    <Card style={{marginTop:"10%"}}><CardBody>
                        <h3 className="formHeader">Delete Work package</h3>
                            <form onSubmit={submitForm}>
                                <div className="form-group">
                                    Are you sure you want to delete the work package?
                                </div>
                                <button type="submit" id="submit" className="btn dangerBtn">Delete</button> <br/> <br/>
                                <button type="submit" id="submit" className="btn dangerCancelBtn" onClick={handleCancel}>Cancel</button> <br/>
                                {error === true ?
                                <div style={{width:'500px'}} className="alert alert-danger mt-3 mb-0">{message}</div> : <div></div>
                            }
                            </form>
                    </CardBody>
                    </Card>
                </div>
                </div>
            </div>
        )}else{
            return (
                <Login/>
            )
        }
    }
    export default WorkPackageDelete;
