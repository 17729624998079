import React, {Component} from 'react';
import './Nav.css';
import logo from '../../assets/img/logo-finki.png'
import {BsList} from "react-icons/all";
import {Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";


class Nav extends Component {


    render() {
        return (
            <nav className="nav">
                <div className="logo-nav">
                    <div className="logo-container">
                        <Link to="/">
                        <div className="logotext" style={{display: "flex", alignItems: "center"}}>
                            <img src={logo} alt="Logo" id="logo"/>
                            <div className="title-nav">Timesheet</div>
                        </div>
                        </Link>
                    </div>

                </div>


            </nav>

        );
    }
}

export default Nav;